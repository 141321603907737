import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllFarms } from '../../utils/api';

const env = process.env.NODE_ENV || "development";
const config = require(`../../config/appsettings${env === "production" ? "" : ".development"
    }`);

export default function ServiceApp() {

    const [farmList, setFarmList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await getAllFarms().then((res) => {
                setFarmList(res);
            });
        };

        fetchData();
    }, []);

    const getFarmImage = (farm) => {
        if (farm.images.length > 0) {
            return config.apiUrl + "/farm/image/" + farm.images[0].id;
        } else {
            return publicUrl + "assets/images/services/services-v2-img1.jpg";
        }
    }
    let publicUrl = process.env.PUBLIC_URL + '/'
    return (
        <>
            <section className="services-two">
                <div className="services-two__bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/slides/slide1.jpg)' }}></div>
                <div className="services-two__bg2" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/shapes/services-v2-shape2.png)' }}></div>
                <div className="shape1 wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
                    <img className="float-bob-y" src={publicUrl + "assets/images/shapes/services-v2-shape3.png"} alt="#" />
                </div>
                <div className="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms">
                    <img className="float-bob-y" src={publicUrl + "assets/images/shapes/services-v2-shape4.png"} alt="#" />
                </div>
                <div className="container">
                    <div className="sec-title style2 text-center">
                        <div className="sec-title__tagline center">
                            <div className="img-box"><img src={publicUrl + "assets/images/resources/sec-title-img.png"} alt="#" /></div>
                            <h6>ฟาร์มในเครือ</h6>
                        </div>
                        <h2 className="sec-title__title">
                            ฟาร์มบางส่วนในเครือข่ายของเรา</h2>
                    </div>

                    <div className="row">
                        {farmList.map((farm, index) => (
                            <div key={index} className="col-xl-4 col-lg-6 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="services-two__single">
                                    <div className="services-two__single-inner">
                                        <div className="services-two__single-img">
                                            <div className="inner">
                                            <Link to={process.env.PUBLIC_URL + `/farm/`+farm.id}><img src={getFarmImage(farm)} alt={farm.name} className="img-fluid crop" /></Link>
                                            </div>
                                        </div>
                                        <div className="services-two__single-content">
                                            <div className="services-two__single-content-bg" style={{ backgroundImage: 'url(' + publicUrl + 'assets/images/shapes/services-v2-shape1.png)' }}>
                                            </div>
                                            <h2><Link to={process.env.PUBLIC_URL + `/farm/`+farm.id}>{farm.name}</Link></h2>
                                            <p className="services-two__single-content-desc">{farm.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
        </>
    )
}