import React, { useEffect, useState, useContext } from 'react';
import * as AiIcons from 'react-icons/ai';
import { AiOutlineBars } from 'react-icons/ai';
import { CgChevronDown, CgChevronLeft } from 'react-icons/cg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../context/AuthContext';


const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 50px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    &:hover {
        background: #123316;
        color: #fff;
        cursor: pointer;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 0px;
    color: #fff;
    font-weight: 400;
`;

const DropdownLink = styled(Link)`
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    &:hover,
    &:active {
        background: #123316;
        cursor: pointer;
        color: #fff;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);

    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <SidebarLink to={process.env.PUBLIC_URL + `${item.path}`} onClick={item.clickEvent
                ? item.clickEvent
                : item.subNav
                    ? showSubnav
                    : null}>
                <div>
                    {item.icon}
                    <SidebarLabel>{item.title}</SidebarLabel>
                </div>
                <div>
                    {item.subNav && subnav ? item.iconOpened : item.subNav ? item.iconClosed : null}
                </div>
            </SidebarLink>
            {subnav &&
                item.subNav.map((data, index) => (
                    <DropdownLink to={process.env.PUBLIC_URL + `${data.path}`} key={index} onClick={data.clickEvent ? data.clickEvent : undefined}>
                        {item.icon}
                        <SidebarLabel>{data.title}</SidebarLabel>
                    </DropdownLink>
                ))}
        </>
    );
};

const NavIcon = styled(Link)`
    color: #123316;
    font-size: 2rem;
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
`;

const SidebarNav = styled.nav`
    background-color: #3cab41;
    width: 300px;
    height: 100%;
    position: fixed;
    overflow-y: scroll;
    scroll-behavior: smooth;
    -webkit-scroll-behavior: smooth;
    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
    top: 0;
    right: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
    transition: 350ms;
    z-index: 99999;
`;

const SidebarWrap = styled.div`
    width: 100%;
`;

const MobileMenu = () => {
    const [sidebar, setSidebar] = useState(false);
    const [menuData, setMenuData] = useState([]);
    const { userInfo, setUserInfo, logout } = useContext(AuthContext);

    const showSidebar = () => setSidebar(!sidebar);
    let publicUrl = process.env.PUBLIC_URL + '/'

    const handleLogout = (event) => {
        event.preventDefault();
        logout();
    };

    useEffect(() => {
        if(userInfo){
            setMenuData(menuDataDefaultLogin);
        }else{
            setMenuData(menuDataDefault);
        }
    }, [userInfo]);

    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            var uinfo = JSON.parse(localStorage.getItem('userInfo'));
            if (!uinfo) {
                setUserInfo({
                    token: null,
                    userInfo: null,
                });
            } else {
                setUserInfo({
                    token: storedToken,
                    userInfo: uinfo,
                });
            }
        }
    }, [setUserInfo]);

    const menuDataDefault = [
        {
            title: 'หน้าหลัก',
            path: '/',
        },
        {
            title: 'เกี่ยวกับเรา',
            path: '/about',
        },
        {
            title: 'ติดต่อเรา',
            path: '/contact',
        },
        {
            title: 'เข้าสู่ระบบ',
            path: '/login',
        }
    ];

    const menuDataDefaultLogin = [
        {
            title: 'หน้าหลัก',
            path: '/',
        },
        {
            title: 'เกี่ยวกับเรา',
            path: '/about',
        },
        {
            title: 'ติดต่อเรา',
            path: '/contact',
        },
        {
            title: 'สำหรับเจ้าหน้าที่',
            path: '/agent',
        }
    ];

    const menuDataAgentDefault = [
        {
            title: 'หน้าหลัก',
            path: '/',
        },
        {
            title: 'หน้าหลักเจ้าหน้าที่',
            path: '/agent',
        },
    ];

    const menuFarmer = [
        {
            title: 'แบบคำขอตรวจฟาร์ม',
            path: '/agent/reqaudit',
        },
        {
            title: 'จัดการข้อมูลฟาร์ม',
            path: '#',

            iconClosed: <CgChevronLeft />,
            iconOpened: <CgChevronDown />,

            subNav: [
                {
                    title: 'แก้ไขข้อมูลฟาร์ม',
                    path: '/agent/farm/edit',
                },
                {
                    title: 'จัดการรูปภาพ',
                    path: '/agent/farm/image',
                }
            ],
        },
    ]

    const menuUser = [
        {
            title: 'ข้อมูลส่วนตัว',
            path: '#',

            iconClosed: <CgChevronLeft />,
            iconOpened: <CgChevronDown />,

            subNav: [
                {
                    title: 'แก้ไขข้อมูลส่วนตัว',
                    path: '/account',
                },
                {
                    title: 'เปลี่ยนรหัสผ่าน',
                    path: '/changepassword',
                },
                {
                    title: 'ออกจากระบบ',
                    path: '/',
                    clickEvent: handleLogout
                }
            ],
        },
    ];

    useEffect(() => {
        setMenuData(menuDataDefault);
    }, []);

    return (
        <>
            <>
                <NavIcon to="#" style={{ justifyContent: 'flex-end' }}>
                    <AiOutlineBars onClick={showSidebar} />
                </NavIcon>

                <SidebarNav sidebar={sidebar}>
                    <SidebarWrap>
                        <div className="mobile-nav__content">
                            <div className="logo-box">
                                <Link to={process.env.PUBLIC_URL + `/`} aria-label="logo image"><img src={publicUrl + "assets/images/resources/mobile-nav-logo.png"} width="155" alt="" /></Link>
                            </div>
                            <NavIcon to="#">
                                <AiIcons.AiOutlineClose
                                    style={{
                                        color: 'white',
                                        fontSize: '18px',
                                        justifyContent: 'flex-start',
                                    }}
                                    onClick={showSidebar}
                                />
                            </NavIcon>
                            {menuData.map((item, index) => (
                                <SubMenu item={item} key={index} />
                            ))}
                            {/* <ul className="mobile-nav__contact list-unstyled">
                                <li>
                                    <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:needhelp@packageName__.com">needhelp@const.com</a>
                                </li>
                                <li>
                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                    <a href="tel:666-888-0000">666 888 0000</a>
                                </li>
                            </ul>
                            <div className="mobile-nav__top">
                                <div className="mobile-nav__social">
                                    <a href="https://twitter.com/"><span className="fab fa-twitter"></span></a>
                                    <a href="https://facebook.com/"><span className="fab fa-facebook-square"></span></a>
                                    <a href="https://pinterest.com/"><span className="fab fa-pinterest-p"></span></a>
                                    <a href="https://instagram.com/"><span className="fab fa-instagram"></span></a>
                                </div>
                            </div> */}
                        </div>
                    </SidebarWrap>
                </SidebarNav>
            </>
        </>
    );
};

export default MobileMenu;