import React from 'react';
import {Link} from 'react-router-dom';

export default class FooterApp extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <footer className="footer-one">
                    <div className="footer-one__bg" style={{backgroundImage: 'url('+publicUrl+'assets/images/shapes/footer-v1-shape3.png)'}}></div>
                    <div className="shape1 float-bob-y"><img src={publicUrl+"assets/images/shapes/footer-v1-shape1.png"} alt="#" /></div>
                    <div className="shape2 float-bob-y"><img src={publicUrl+"assets/images/shapes/footer-v1-shape2.png"} alt="#" /></div>
                    {/* Start Footer */}
                    <div className="footer">
                        <div className="container">
                            <div className="row">
                                {/* Start Footer Widget Single */}
                                <div className="col-xl-5 col-lg-5  wow animated fadeInUp" data-wow-delay="0.1s">
                                    <div className="footer-widget__single">
                                        <div className="footer-widget__single-about">
                                            <div className="logo-box">
                                                <Link to={process.env.PUBLIC_URL + `/`}><img src={publicUrl+"assets/images/resources/logomini-white.png"} alt="#" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Footer Widget Single */}

                                {/* Start Footer One Right */}
                                <div className="col-xl-7 col-lg-7">
                                    <div className="footer-one__right">
                                        <div className="row">
                                            {/* Start Footer One Right Single */}
                                            <div className="col-xl-3 col-lg-3 wow animated fadeInUp" data-wow-delay="0.3s">
                                                <div className="footer-one__right-single mb50">
                                                    <div className="title">
                                                        <h2>Links</h2>
                                                    </div>
                                                    <div className="footer-one__right-single-links">
                                                        <ul className="footer-one__right-single-list">
                                                            <li><Link to={process.env.PUBLIC_URL + `#`}>About Us</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `#`}>Our Team</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `#`}>Contact Us</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `#`}>Our History</Link></li>
                                                            <li><Link to={process.env.PUBLIC_URL + `#`}>Testimonials</Link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}

                                            {/* Start Footer One Right Single */}
                                            <div className="col-xl-5 col-lg-5 wow animated fadeInUp" data-wow-delay="0.4s">
                                                <div className="footer-one__right-single">
                                                    <div className="title">
                                                        <h2>Contact</h2>
                                                    </div>
                                                    <div className="footer-one__right-single-contact">
                                                        <p> <a href="mailto:yourmail@email.com">sangsook.ubon@gmail.com</a>
                                                            <br /> 330/5 หมู่ 17 ตำบลไร่น้อย <br />
                                                            อำเภอเมือง จังหวัดอุบลราชธานี</p>
                                                        <a href=" mailto:yourmail@email.com">sangsook.ubon@gmail.com</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Footer One Right Single */}
                                        </div>

                                        <div className="footer-one__right-bottom wow animated fadeInUp" data-wow-delay="0.1s">
                                            <ul className="social-links">
                                                <li><Link to={'https://www.facebook.com/Ginsabaijai/'} target={'_blank'}><span className="icon-facebook"></span></Link></li>
                                            </ul>

                                            <div className="footer-one__right-bottom-contact">
                                                <div className="icon-box">
                                                    <span className="icon-phone-call"></span>
                                                </div>
                                                <div className="content-box">
                                                    <p>Call Anytime</p>
                                                    <h4><a href="tel:0873786955">087 378 6955</a></h4>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* End Footer One Right */}
                            </div>
                        </div>
                    </div>


                    <div className="footer-one__bottom">
                        <div className="container">
                            <div className="bottom-inner">
                                <div className="copyright">
                                    <p>Copyright © 2023 All Rights Reserved.</p>
                                </div>

                                <ul className="footer-one__bottom-menu">
                                    <li><Link to={process.env.PUBLIC_URL + `#`}>Terms & Condition</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `#`}>Privacy </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </footer>
            </>
        )
    }
}