import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import LogoTwo from "./LogoTwo";
import { AuthContext } from "../../context/AuthContext";
import NavAgent from "./NavAgent";
import MobileMenuAgent from "./MobileMenuAgent";
import LogoGinsabaijai from "./LogoGinsabaijai";

export default function HeaderAgent() {
  const { userInfo } = useContext(AuthContext);
  let publicUrl = process.env.PUBLIC_URL + "/";
  return (
    <>
      <header className="main-header main-header-one main-header-two">
        {/* Start Main Header One Bottom */}
        <div className="main-header-one__bottom">
          <div
            className="main-header-two__bottom-bg"
            style={{
              backgroundImage:
                "url(" +
                publicUrl +
                "assets/images/shapes/main-header-v2-bg.png)",
            }}
          ></div>
          <div className="main-header-one__bottom-inner">
            <nav className="main-menu main-menu-one">
              <div className="main-menu__wrapper clearfix">
                <div className="auto-container">
                  <div className="main-menu__wrapper-inner">
                    <div className="main-header-one__bottom-left">
                      <LogoGinsabaijai />
                    </div>

                    <div className="main-header-one__bottom-middle">
                      <div className="main-menu-box">
                        <MobileMenuAgent />
                        <NavAgent />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* End Main Header Two Bottom */}
      </header>
    </>
  );
}
