export const getRequestAuditStatusDisply = (status) => {
  switch (status) {
    case 0:
      return "ฉบับร่าง";
    case 1:
      return "ยื่นคำขอ";
    case 2:
      return "ส่งกลับไปแก้ไข";
    case 3:
      return "ไม่อนุมัติ";
    case 4:
      return "ยกเลิก";
    case 5:
      return "อนุมัติ";
    case 6:
      return "ผ่านการประเมิน";
    case 7:
      return "ไม่ผ่านการประเมิน";
    default:
      return "ไม่ระบุ";
  }
};

export const getAuditStatusDisply = (status) => {
  switch (status) {
    case 0:
      return "ผ่านการรับรองมาตรฐานแบบไม่มีเงื่อนไข";
    case 1:
      return "ผ่านการรับรองมาตรฐานแบบมีเงื่อนไข";
    case 2:
      return "ไม่ผ่าน";
    case 3:
      return "เลื่อนการรับรอง";
    case 4:
      return "อยู่ระหว่างการประเมิน";
    default:
      return "ไม่ระบุ";
  }
};