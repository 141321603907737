import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";
import {
  getReqAuditById,
  changeReqAuditStatus,
  getReqAuditList,
  getAuditById,
} from "../../utils/api";
import { useTable, useSortBy } from "react-table";
import { Col, Card, Row, Modal, Button } from "react-bootstrap";
import BlockUI from "../../common/BlockUI/BlockUI";
import { getThaiDateTime } from "../../utils/general";
import { getRequestAuditStatusDisply } from "../../utils/audit";
import AgentReqAduitDetail from "../../components/agent/ReqAuditDetail";

export default function AgentReqAduitList() {
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [reqAuditList, setReqAuditList] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [showDetail, setShowDetail] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [formData, setFormData] = useState({});

  const [errorMessage, setErrorMessage] = useState("");
  const errorRef = React.useRef(null);

  const env = process.env.NODE_ENV || "development";
  const config = require(`../../config/appsettings${
    env === "production" ? "" : ".development"
  }`);

  const navigate = useNavigate();

  const displayOperationItem = (onClickHistory, onClickDetail) => {
    var result = (
      <>
        <button className="btn btn-primary btn-sm ms-2" onClick={onClickDetail}>
          ดูรายละเอียด
        </button>
        <button
          className="btn btn-primary btn-sm ms-2"
          onClick={onClickHistory}
        >
          ดูประวัติ
        </button>
      </>
    );

    return result;
  };

  useEffect(() => {
    if (errorMessage && errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);

      setLoading(true);
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        var uinfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!uinfo) {
          logout();
          navigate("/login");
        } else {
          if (!userInfo) {
            setUserInfo({
              token: storedToken,
              userInfo: uinfo,
            });
          }
          if (
            !(
              uinfo?.roles?.some((role) => role.code === "ADMIN") ||
              uinfo?.roles?.some((role) => role.code === "AUDITOR")
            )
          ) {
            navigate("/agent");
          }

          // Application logic
          try {
            const fetchData = async () => {
              await getReqAuditList().then((data) => {
                setReqAuditList(data);
              });
            };

            fetchData();
          } catch (error) {
            setErrorMessage(error);
          } finally {
            setLoading(false);
          }
        }
      } else {
        logout();
        navigate("/login");
      }
    }
  }, [setUserInfo, logout, userInfo, navigate]);

  const getLogHistory = (reqAudit, dir = "asc") => {
    if (dir === "desc") {
      return reqAudit.sort((a, b) => {
        return new Date(b.created) - new Date(a.created);
      });
    } else {
      return reqAudit.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
      });
    }
  };

  const handleClickChangeState = async (status) => {
    try {
      let id = formData.id;
      if (status === 5) {
        let approveConfirm = await Swal.fire({
          title: "ยืนยันการอุนมัติ",
          text: "หากดำเนินการอนุมัติแล้ว ระบบจะทำการสร้างรายงานการตรวจฟาร์มฉบับเต็มและไม่สามารถยกเลิกได้",
          showCancelButton: true,
          icon: "warning",
        });

        if (!approveConfirm.isConfirmed) {
          return;
        }
      }
      const { value: text, dismiss } = await Swal.fire({
        title: "ยืนยัน " + getRequestAuditStatusDisply(status),
        input: "textarea",
        inputPlaceholder: "ระบุเหตุผล",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        icon: "warning",
        width: "90%",
      });
      if (dismiss != "cancel") {
        await changeReqAuditStatus(id, status, text)
          .then((data) => {
            Swal.fire({
              title: "Success",
              text: getRequestAuditStatusDisply(status) + " สำเร็จ",
              icon: "success",
            });
            window.location.reload();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: error.message,
              icon: "error",
            });
          });
      }
    } catch (error) {
      await Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleClickDetail = async (id) => {
    setLoading(true);
    await getReqAuditById(id)
      .then((res) => {
        setFormData(res);
        setSelectedFiles(res.requestImage);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถเรียกดูข้อมูลได้ โปรดติดต่อเจ้าหน้าที่",
          text: err.message,
        })
        setLoading(false);
        return;
      });

    setLoading(false);
    setShowDetail(true);
  };
  const handleClickHistory = async (id) => {
    try {
      let historyData = [];
      await getReqAuditById(id)
        .then((data) => {
          historyData = data;
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
      let historyDataSorted = getLogHistory(historyData.logs);
      let tableData = (
        <>
          <table className="table">
            <thead>
              <th scope="col">วันที่</th>
              <th scope="col">ดำเนินการ</th>
              <th scope="col">บันทึก</th>
              <th scope="col">ผู้ดำเนินการ</th>
            </thead>
            <tbody>
              {historyDataSorted.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{getThaiDateTime(new Date(item.created))}</td>
                    <td>{getRequestAuditStatusDisply(item.status)}</td>
                    <td>
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {item.comment}
                      </div>
                    </td>
                    <td>
                      {item.actor?.name} {item.actor?.surName}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      );
      Swal.fire({
        title: "ประวัติการดำเนินการ",
        html: ReactDOMServer.renderToString(tableData),
        icon: null,
        width: "90%",
      });
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
      });
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "ปีที่ยื่นคำขอ",
        accessor: "year",
      },
      {
        Header: "ครั้งที่ยื่นคำขอ",
        accessor: "round",
      },
      {
        Header: "วันที่สร้างเอกสาร",
        accessor: (row) => (
          <>
            {getThaiDateTime(
              new Date(row.createTime)
            )}
          </>
        ),
      },
      {
        Header: "สถานะล่าสุด",
        accessor: (row) => (
          <>
            {getRequestAuditStatusDisply(row.status)}{" "}
            {getThaiDateTime(
              new Date(getLogHistory(row.logs, "desc")[0].created)
            )}
          </>
        ),
      },
      {
        Header: "ดำเนินการ",
        accessor: (row) => (
          <>
            {displayOperationItem(
              () => handleClickHistory(row.id),
              () => handleClickDetail(row.id)
            )}
          </>
        ),
      },
    ],
    []
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          initialState: {
            sortBy: [{ id: "year", desc: false }],
          },
        },
        useSortBy
      );

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20);

    return (
      <>
        <div className="table-responsive">
          <table {...getTableProps()} className="table table-bordered">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {firstPageRows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    style={{
                      backgroundColor:
                        row.original.status === 1 ? "#FDFD96" : "transparent",
                    }}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        <div>Showing the first 20 results of {rows.length} rows</div>
      </>
    );
  }

  return (
    <>
      <HeaderAgent />
      <BlockUI blocking={loading} />
      <section className="general-block">
        <div className="container">
          {errorMessage ? (
            <div className="row">
              <div className="alert alert-danger" ref={errorRef}>
                {errorMessage}
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-xl-12">
                  <h1>รายการคำขอตรวจประเมินฟาร์ม</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/agent/reqauditadd");
                    }}
                  >
                    ยื่นคำขอใหม่
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  {reqAuditList ? (
                    <>
                      <Table columns={columns} data={reqAuditList} />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <Modal
        show={showDetail}
        dialogClassName="modal-xl modal-dialog modal-dialog-scrollable"
        enforceFocus={false}
        onHide={() => setShowDetail(false)}
      >
        <Modal.Header closeButton>รายละเอียดแบบคำขอ</Modal.Header>
        <Modal.Body>
          <AgentReqAduitDetail
            formData={formData}
            selectedFiles={selectedFiles}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {formData.status == 1 ? (
            <div className="d-flex justify-content-start">
              <Button
                className="btn btn-warning btn-sm ms-2"
                onClick={() => {
                  handleClickChangeState(2);
                }}
              >
                ส่งกลับไปแก้ไข
              </Button>
              <Button
                className="btn btn-danger btn-sm ms-2"
                onClick={() => {
                  handleClickChangeState(3);
                }}
              >
                ไม่อนุมัติ
              </Button>
              <Button
                className="btn btn-primary btn-sm ms-2"
                onClick={() => {
                  handleClickChangeState(5);
                }}
              >
                อนุมัติ
              </Button>
            </div>
          ) : null}
          <div className="d-flex justify-content-end">
            <Button
              className="btn btn-danger btn-sm"
              variant="primary"
              onClick={() => {
                setShowDetail(false);
              }}
            >
              ปิด
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <FooterApp />
    </>
  );
}
