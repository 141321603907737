import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import {
  getFarmByUser,
  sendAddReqAudit,
  sendEditFarm,
  sendEditReqAudit,
} from "../../utils/api";
import { Button, Col, Card, Row, Modal } from "react-bootstrap";
import BlockUI from "../../common/BlockUI/BlockUI";
import FileUploadModal from "../../components/general/FileUploadModal";
import { getReqAuditById } from "../../../src/utils/api";
import { getRequestAuditStatusDisply } from "../../utils/audit";
import Swal from "sweetalert2";

const env = process.env.NODE_ENV || "development";
const config = require(`../../config/appsettings${
  env === "production" ? "" : ".development"
}`);

const DeleteFooter = ({ id, handleDelete }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleConfirmDelete = () => {
    handleDelete(id);
    setShowConfirm(false);
  };

  return (
    <Card.Footer>
      {showConfirm ? (
        <div className="delete-confirm">
          <p>ยืนยันการลบ</p>
          <div className="confirm-buttons">
            <Button variant="danger" onClick={handleConfirmDelete}>
              ใช่
            </Button>
            <Button
              variant="secondary"
              className="ms-2"
              onClick={() => setShowConfirm(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant="danger"
          className="btn-sm"
          onClick={() => setShowConfirm(true)}
        >
          × ลบภาพ
        </Button>
      )}
    </Card.Footer>
  );
};

export default function AgentReqAduitEdit() {
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAddImage, setShowAddImage] = useState(false);

  const location = useLocation();
  let reqAuditId = location.state.myObject;

  let publicUrl = process.env.PUBLIC_URL + "/";

  const errorRef = useRef(null);
  useEffect(() => {
    if (errorMessage && errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const [formData, setFormData] = useState({
    id: "",
    round: 0,
    year: new Date().getFullYear() + 543,
    farmName: "",
    desc: "",
    addr: "",
    lat: 0,
    lng: 0,
    phone: "",
    line: "",
    site: "",
    ownername: "",
    site1name: "",
    site1size: 0,
    site1item: "",
    site1status: 0,
    site2name: "",
    site2size: 0,
    site2item: "",
    site2status: 0,
    requestImage: [],
    status: 0,
  });

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "site1status" || name === "site2status") {
      setFormData({ ...formData, [name]: parseInt(value) });
      return;
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDeleteFile = (id) => {
    setSelectedFiles(selectedFiles.filter((img) => img.id !== id));
  };

  const handleShowUploadImage = () => {
    setShowAddImage(true);
  };
  const handleCloseUploadImage = () => {
    setShowAddImage(false);
  };

  const handleSetUploadedList = (uploadedList) => {
    let newUploadedList = [];
    uploadedList.forEach((uploaded) => {
      newUploadedList.push({
        id: uploaded,
        desc: "",
      });
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newUploadedList]);
  };

  const handleImgDescChange = (e, id) => {
    const index = selectedFiles.findIndex((img) => img.id === id);
    const updatedFiles = [...selectedFiles];
    updatedFiles[index] = {
      ...updatedFiles[index],
      desc: e.target.value,
    };
    setSelectedFiles(updatedFiles);
  };

  const handleSave = async (formStatus) => {
    const { value: text, dismiss } = await Swal.fire({
      title: "บันทึกผล - " + getRequestAuditStatusDisply(formStatus),
      input: "textarea",
      inputPlaceholder: "ระบุเหตุผล",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      icon: "warning",
      width: "90%",
    });
    if (dismiss === "cancel") {
      return;
    }

    let newSlelectedFiles = [];

    selectedFiles.map((img, index) => {
      newSlelectedFiles.push({
        id: img.id,
        desc: img.desc,
        order: index,
        isDefault: index === 0 ? true : false,
      });
    });

    const data = {
      id: formData.id,
      round: formData.round,
      year: formData.year,
      farmName: formData.farmName,
      desc: formData.desc,
      addr: formData.addr,
      lat: formData.lat,
      lng: formData.lng,
      phone: formData.phone,
      line: formData.line,
      site: formData.site,
      ownername: formData.ownername,
      site1name: formData.site1name,
      site1size: formData.site1size,
      site1item: formData.site1item,
      site1status: formData.site1status,
      site2name: formData.site2name,
      site2size: formData.site2size,
      site2item: formData.site2item,
      site2status: formData.site2status,
      requestImage: newSlelectedFiles,
      status: formStatus,
      comment: text,
    };
    setLoading(true);
    try {
      sendEditReqAudit(data)
        .then((res) => {
          if (res.status === 200 || res.success) {
            setErrorMessage("");
            navigate("/agent/reqaudit");
            setLoading(false);
          } else {
            setErrorMessage(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
          setLoading(false);
        });
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        var uinfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!uinfo) {
          logout();
          navigate("/login");
        } else {
          if (!userInfo) {
            setUserInfo({
              token: storedToken,
              userInfo: uinfo,
            });
          }
          if (!uinfo?.roles?.some((role) => role.code === "FARMER")) {
            navigate("/agent");
          }
          // Application logic
          try {
            setLoading(true);
            setSelectedFiles([]);
            const fetchData = async () => {
              let reqAuditData = {};
              reqAuditId = location.state.id;
              await getReqAuditById(reqAuditId)
                .then((res) => {
                  reqAuditData = res;
                })
                .catch((err) => {
                  setErrorMessage(
                    "ไม่สามารถเรียกดูข้อมูลได้ โปรดติดต่อเจ้าหน้าที่"
                  );
                  setLoading(false);
                  return;
                });
              setFormData({
                id: reqAuditData.id,
                round: reqAuditData.round,
                year: reqAuditData.year,
                farmName: reqAuditData.farmName,
                desc: reqAuditData.desc,
                addr: reqAuditData.addr,
                lat: reqAuditData.lat,
                lng: reqAuditData.lng,
                phone: reqAuditData.phone,
                line: reqAuditData.line,
                site: reqAuditData.site,
                ownername: reqAuditData.ownername,
                site1name: reqAuditData.site1name,
                site1size: reqAuditData.site1size,
                site1item: reqAuditData.site1item,
                site1status: reqAuditData.site1status,
                site2name: reqAuditData.site2name,
                site2size: reqAuditData.site2size,
                site2item: reqAuditData.site2item,
                site2status: reqAuditData.site2status,
                requestImage: [],
              });
              setSelectedFiles(reqAuditData.requestImage);
            };

            fetchData();
          } catch (error) {
            setErrorMessage(error);
          } finally {
            setLoading(false);
          }
        }
      } else {
        logout();
        navigate("/login");
      }
    }
  }, [navigate, userInfo, setUserInfo, logout, isFirstLoad, setIsFirstLoad]);

  return (
    <>
      <HeaderAgent />
      <section className="general-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>ยื่นคำขอตรวจฟาร์ม</h1>
            </div>
          </div>
          {errorMessage ? (
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="alert alert-danger" ref={errorRef}>
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <form>
            {/* General */}
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">ข้อมูลทั่วไป</h5>
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="tbxReqYear" className="form-label">
                          ปีที่ยื่นคำขอ
                        </label>
                        <input
                          autoComplete="off"
                          type="number"
                          min={2566}
                          className="form-control"
                          id="tbxReqYear"
                          placeholder="2566"
                          name="year"
                          value={formData.year}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="mb-3">
                        <label htmlFor="tbxReqRound" className="form-label">
                          ครั้งที่ยื่นคำขอ
                        </label>
                        <input
                          autoComplete="off"
                          type="number"
                          min={0}
                          className="form-control"
                          id="tbxReqRound"
                          placeholder="1"
                          name="round"
                          readOnly={true}
                          value={formData.round}
                          // onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label htmlFor="tbxFarmName" className="form-label">
                          ชื่อฟาร์ม
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="tbxFarmName"
                          placeholder="ชื่อฟาร์ม"
                          name="farmName"
                          value={formData.farmName}
                          // onChange={handleInputChange}
                          readOnly={true}
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="tbxOwnerName" className="form-label">
                          เจ้าของฟาร์ม
                        </label>
                        <input
                          autoComplete="off"
                          type="text"
                          className="form-control"
                          id="tbxOwnerName"
                          placeholder="ชื่อเจ้าของฟาร์ม"
                          name="ownername"
                          value={formData.ownername}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="mb-3">
                        <label htmlFor="tbxAddr" className="form-label">
                          ที่อยู่
                        </label>
                        <textarea
                          autoComplete="off"
                          className="form-control"
                          id="tbxAddr"
                          placeholder="ที่อยู่"
                          name="addr"
                          value={formData.addr}
                          onChange={handleInputChange}
                          rows="4"
                        />
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <label htmlFor="tbxLat" className="form-label">
                            Latitude
                          </label>
                          <input
                            autoComplete="off"
                            type="number"
                            className="form-control"
                            id="tbxLat"
                            placeholder="15.2494865"
                            name="lat"
                            value={formData.lat}
                            onChange={handleInputChange}
                            pattern="^\d*(\.\d{0,6})?$"
                            step={0.000001}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="tbxLng" className="form-label">
                            Longitude
                          </label>
                          <input
                            autoComplete="off"
                            type="number"
                            className="form-control"
                            id="tbxLng"
                            placeholder="104.8496195"
                            name="lng"
                            value={formData.lng}
                            onChange={handleInputChange}
                            pattern="^\d*(\.\d{0,6})?$"
                            step={0.000001}
                          />
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-4">
                          <label htmlFor="tbxPhone" className="form-label">
                            โทรศัพท์
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxPhone"
                            placeholder="0812345678"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="tbxSite" className="form-label">
                            เว็บไซต์
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxSite"
                            placeholder="https://www.google.com"
                            name="site"
                            value={formData.site}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label htmlFor="tbxLine" className="form-label">
                            Line ID
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxLine"
                            placeholder="@ginsabaijai"
                            name="line"
                            value={formData.line}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ReqInfo Site 1 */}
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">ข้อมูลแปลงที่ 1</h5>
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <label htmlFor="tbxSite1Name" className="form-label">
                        ชื่อแปลง
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="tbxSite1Name"
                        placeholder="ชื่อแปลง"
                        name="site1name"
                        value={formData.site1name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="tbxSite1Size" className="form-label">
                        ขนาดแปลง (ไร่)
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        step={0.01}
                        className="form-control"
                        id="tbxSite1Size"
                        placeholder="0"
                        name="site1size"
                        value={formData.site1size}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <fieldset>
                        <legend
                          htmlFor="rbSite1Status"
                          className="form-label legend-label"
                        >
                          สถานะ
                        </legend>
                        <div
                          className="form-check form-check-inline"
                          id="rbSite1Status"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="site1status"
                            id="rbSite1Ststus0"
                            value={0}
                            checked={formData.site1status === 0}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rbSite1Ststus0"
                          >
                            ออแกนิค
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="site1status"
                            id="rbSite1Ststus1"
                            value={1}
                            checked={formData.site1status === 1}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rbSite1Ststus1"
                          >
                            ปรับเปลี่ยน
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tbxSite1Item" className="form-label">
                        รายการพื่ช/สัตว์ที่ขอรับรอง
                      </label>
                      <textarea
                        autoComplete="off"
                        className="form-control"
                        id="tbxSite1Item"
                        placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                        name="site1item"
                        value={formData.site1item}
                        onChange={handleInputChange}
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ReqInfo Site 2 */}
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <h5 className="card-title">ข้อมูลแปลงที่ 2</h5>
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <label htmlFor="tbxSite2Name" className="form-label">
                        ชื่อแปลง
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="tbxSite2Name"
                        placeholder="ชื่อแปลง"
                        name="site2name"
                        value={formData.site2name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <label htmlFor="tbxSite2Size" className="form-label">
                        ขนาดแปลง (ไร่)
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        step={0.01}
                        className="form-control"
                        id="tbxSite2Size"
                        placeholder="0"
                        name="site2size"
                        value={formData.site2size}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3 col-md-6">
                      <fieldset>
                        <legend
                          htmlFor="rbSite2Status"
                          className="form-label legend-label"
                        >
                          สถานะ
                        </legend>
                        <div
                          className="form-check form-check-inline"
                          id="rbSite2Status"
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="site2status"
                            id="rbSite2Ststus0"
                            value={0}
                            checked={formData.site2status === 0}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rbSite2Ststus0"
                          >
                            ออแกนิค
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="site2status"
                            id="rbSite2Ststus1"
                            value={1}
                            checked={formData.site2status === 1}
                            onChange={handleInputChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rbSite2Ststus1"
                          >
                            ปรับเปลี่ยน
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="tbxSite2Item" className="form-label">
                        รายการพื่ช/สัตว์ที่ขอรับรอง
                      </label>
                      <textarea
                        autoComplete="off"
                        className="form-control"
                        id="tbxSite2Item"
                        placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                        name="site2item"
                        value={formData.site2item}
                        onChange={handleInputChange}
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ReqImage */}
            <Row>
              <Card>
                <Card.Body>
                  <Row>
                    <h5 className="card-title">
                      ภาพประกอบ
                      <Button
                        id="btnAddImage"
                        variant="primary"
                        className="align-bottom btn-sm ms-2"
                        onClick={handleShowUploadImage}
                      >
                        เพิ่มรูปประกอบ
                      </Button>
                    </h5>
                    {selectedFiles.map((img) => (
                      <>
                        <Col md={4} className="mb-4" key={img.id}>
                          <Card className="h-100">
                            <div className="card-image-container">
                              <Link
                                to={
                                  config.apiUrl +
                                  "/registeraudit/image/" +
                                  img.id
                                }
                                target="_blank"
                              >
                                <Card.Img
                                  variant="top"
                                  src={
                                    config.apiUrl +
                                    "/registeraudit/image/" +
                                    img.id
                                  }
                                  className="card-image"
                                />
                              </Link>
                            </div>
                            <Card.Body>
                              <Card.Text>
                                <label
                                  htmlFor="tbxImgDesc"
                                  className="form-label"
                                >
                                  คำอธิบายภาพ
                                </label>
                                <textarea
                                  autoComplete="off"
                                  className="form-control"
                                  id="tbxImgDesc"
                                  placeholder=""
                                  name="tbxImgDesc"
                                  value={img.desc}
                                  onChange={(e) =>
                                    handleImgDescChange(e, img.id)
                                  }
                                  rows="2"
                                />
                              </Card.Text>
                            </Card.Body>
                            <DeleteFooter
                              id={img.id}
                              handleDelete={handleDeleteFile}
                            />
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>
                </Card.Body>
              </Card>
            </Row>

            <Row>
              <Col md={12}>
                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={() => {
                    navigate("/agent/reqaudit");
                  }}
                >
                  ยกเลิกการแก้ไข
                </Button>
                <Button
                  variant="warning"
                  className="ms-2"
                  onClick={() => {
                    handleSave(0);
                  }}
                >
                  บันทึกฉบับร่าง
                </Button>
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={() => {
                    handleSave(1);
                  }}
                >
                  ส่งคำขอ
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </section>
      <FooterApp />
      <FileUploadModal
        showModal={showAddImage}
        handleClose={handleCloseUploadImage}
        setUploadedList={handleSetUploadedList}
        source="reqaudit"
      />
      <BlockUI blocking={loading} />
    </>
  );
}
