import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterApp from "../common/footer/FooterApp";
import { AuthContext } from "../context/AuthContext";
import HeaderAgent from "../common/header/HeaderAgent";
import { getFarmByUser, getUserById } from "../utils/api";
import BlockUI from "../common/BlockUI/BlockUI";

export default function Agent() {
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFarmer, setIsFarmer] = useState(false);
  const [isAudit, setIsAudit] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [farmInfo, setFarmInfo] = useState(null);

  const navigate = useNavigate();

  const errorRef = useRef(null);
  useEffect(() => {
      if (errorMessage && errorRef.current) {
          errorRef.current.focus();
      }
  }, [errorMessage]);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);

      setLoading(true);
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        let uinfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!uinfo) {
          logout();
          navigate("/login");
        } else {
          const fetchData = async () => {
            uinfo = await getUserById(uinfo.id);
            setUserInfo({
              token: storedToken,
              userInfo: uinfo,
            });
            let _isAdmin = uinfo?.roles?.some((role) => role.code === "ADMIN");
            let _isFarmer = uinfo?.roles?.some((role) => role.code === "FARMER");
            let _isAudit = uinfo?.roles?.some((role) => role.code === "AUDITOR");
            setIsAdmin(uinfo?.roles?.some((role) => role.code === "ADMIN"));
            setIsFarmer(uinfo?.roles?.some((role) => role.code === "FARMER"));
            setIsAudit(uinfo?.roles?.some((role) => role.code === "AUDITOR"));

            // Application logic
            try {
              if (_isFarmer) {
                getFarmByUser(uinfo.id).then((res) => { setFarmInfo(res) });
              }
            } catch (error) {
              setErrorMessage(error);
            } finally {
              setLoading(false);
            }
          };
          fetchData();
        }
      } else {
        logout();
        navigate("/login");
      }
    }
  }, [setUserInfo, logout, userInfo, navigate]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <>
      <HeaderAgent />
      <BlockUI blocking={loading} />
      <section className="general-block">
        <div className="container">
          {errorMessage ? (
            <div className="row">
              <div className="alert alert-danger" ref={errorRef}>{errorMessage}</div>
            </div>
          ) : null}
          {farmInfo ? (<>
            <div className="row">
              <div className="col-xl-12">

              </div>
            </div>
          </>) : null}
        </div>
      </section>
      <FooterApp />
    </>
  );
}
