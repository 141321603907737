import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function NavApp() {
  const location = useLocation();
  const { userInfo, setUserInfo, logout, CheckIsAgentOutside } = useContext(AuthContext);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      var uinfo = JSON.parse(localStorage.getItem('userInfo'));
      if (!uinfo) {
        setUserInfo({
          token: null,
          userInfo: null,
        });
      } else {
        setUserInfo({
          token: storedToken,
          userInfo: uinfo,
        });
      }
      CheckIsAgentOutside();
    }
  }, [setUserInfo]);
  return (
    <ul className="main-menu__list">
      <li
        className={`dropdown ${
          location.pathname === "/" ? "current" : ""
        }`}
      >
        <Link to={process.env.PUBLIC_URL + `/`}>
          หน้าหลัก <span className="line"></span>
        </Link>
      </li>
          <li className={`dropdown ${location.pathname === '/about' ? 'current' : ''}`}>
        <Link to={process.env.PUBLIC_URL + `#`}>
          เกี่ยวกับเรา <span className="line"></span>
        </Link>
      </li>
          <li className={`dropdown ${location.pathname === '/contact' ? 'current' : ''}`}>
        <Link to={process.env.PUBLIC_URL + `#`}>
          ติดต่อเรา <span className="line"></span>
        </Link>
      </li>
      {userInfo ? (
        <>
          <li className={`dropdown ${location.pathname === '/agent' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/agent`}>
              สำหรับเจ้าหน้าที่ <span className="line"></span>
            </Link>
          </li>
        </>
      ) : (
        <>
          <li className={`dropdown ${location.pathname === '/login' ? 'current' : ''}`}>
            <Link to={process.env.PUBLIC_URL + `/login`}>
              เข้าสู่ระบบ <span className="line"></span>
            </Link>
          </li>
        </>
      )}
    </ul>
  );
}
