import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function NavAgent() {
  const location = useLocation();
  const { userInfo, setUserInfo, logout, CheckIsAgent } =
    useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFarmer, setIsFarmer] = useState(false);
  const [isAudit, setIsAudit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      var uinfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!uinfo) {
        //logout();
      } else {
        setUserInfo({
          token: storedToken,
          userInfo: uinfo,
        });
        CheckIsAgent();
        setIsAdmin(uinfo?.roles?.some((role) => role.code === "ADMIN"));
        setIsFarmer(uinfo?.roles?.some((role) => role.code === "FARMER"));
        setIsAudit(uinfo?.roles?.some((role) => role.code === "AUDITOR"));
      }
    }
  }, [setUserInfo]);

  const handleLogout = (event) => {
    event.preventDefault();
    logout();
    navigate("/");
  };
  return (
    <ul className="main-menu__list">
      {userInfo ? (
        <>
          <li
            className={`dropdown ${location.pathname === "/" ? "current" : ""}`}
          >
            <Link to={process.env.PUBLIC_URL + `/`}>
              หน้าหลัก <span className="line"></span>
            </Link>
          </li>

          <li
            className={`dropdown ${
              location.pathname === "/agent" ? "current" : ""
            }`}
          >
            <Link to={process.env.PUBLIC_URL + `/agent`}>
              หน้าหลักเจ้าหน้าที่ <span className="line"></span>
            </Link>
          </li>
          {isAdmin ? (
            <>
              <li
                className={`dropdown ${
                  location.pathname === "/agent/user" ? "current" : ""
                }`}
              >
                <Link to={process.env.PUBLIC_URL + `/agent/user`}>
                  จัดการผู้ใช้ <span className="line"></span>
                </Link>
              </li>
            </>
          ) : (
            <> </>
          )}
          {isAdmin ? (
            <>
              <li
                className={`dropdown ${
                  location.pathname === "/agent/farm" ? "current" : ""
                }`}
              >
                <Link to={process.env.PUBLIC_URL + `/agent/farm`}>
                  จัดการฟาร์ม <span className="line"></span>
                </Link>
              </li>
            </>
          ) : (
            <> </>
          )}
          {isAdmin || isAudit ? (
            <>
              <li
                className={`dropdown ${
                  location.pathname === "/agent/reqauditlist" ||
                  location.pathname === "/agent/auditform"
                    ? "current"
                    : ""
                }`}
              >
                <Link to={process.env.PUBLIC_URL + `#`}>
                  ระบบตรวจฟาร์ม <span className="line"></span>
                </Link>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + `/agent/reqauditlist`}>
                      รายการคำขอตรวจฟาร์ม
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + `/agent/auditlist`}>
                      แบบตรวจฟาร์ม
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            <> </>
          )}

          {isFarmer ? (
            <>
              <li
                className={`dropdown ${
                  location.pathname === "/agent/reqaudit" ||
                  location.pathname === "/agent/reqauditadd" ||
                  location.pathname === "/agent/reqauditedit"
                    ? "current"
                    : ""
                }`}
              >
                <Link to={process.env.PUBLIC_URL + `/agent/reqaudit`}>
                  แบบคำขอตรวจฟาร์ม <span className="line"></span>
                </Link>
              </li>
            </>
          ) : (
            <> </>
          )}
          {isFarmer ? (
            <>
              <li
                className={`${
                  location.pathname === "/agent/farm/edit" ? "current" : ""
                }`}
              >
                <Link to={process.env.PUBLIC_URL + `/agent/farm/edit`}>
                  จัดการข้อมูลฟาร์ม <span className="line"></span>
                </Link>
              </li>
            </>
          ) : (
            <> </>
          )}

          <li
            className={`dropdown ${
              location.pathname === "/agent/account" ||
              location.pathname === "/agent/changepassword"
                ? "current"
                : ""
            }`}
          >
            <Link to={process.env.PUBLIC_URL + `#`}>
              ข้อมูลส่วนตัว <span className="line"></span>
            </Link>
            <ul>
              <li>
                <Link to={process.env.PUBLIC_URL + `/agent/account`}>
                  แก้ไข้ข้อมูลส่วนตัว
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + `/agent/changepassword`}>
                  เปลี่ยนรหัสผ่าน
                </Link>
              </li>
              <li>
                <Link onClick={handleLogout}>ออกจากระบบ</Link>
              </li>
            </ul>
          </li>
        </>
      ) : (
        <li>
          <Link to={process.env.PUBLIC_URL + `/login`}>
            เข้าสู่ระบบ <span className="line"></span>
          </Link>
        </li>
      )}
    </ul>
  );
}
