import React, { createContext, useState, useEffect } from 'react';
import api, { APIIsAgent } from '../utils/api';
import jwt_decode from 'jwt-decode';
import { getUserById, logout as APILogout } from '../utils/api';

const AuthContext = createContext({
  userInfo: null,
  setUserInfo: () => { },
  login: () => { },
  logout: () => { },
  refreshTokens: () => { },
});

const AuthContextProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = jwt_decode(storedToken);
      const currentTime = Date.now() / 1000;
      var uinfo = localStorage.getItem('userInfo');
      if (decodedToken.exp < currentTime || !decodedToken || uinfo === 'undefined') {
        logout();
      } else {
        setUserInfo({
          token: storedToken,
          userInfo: JSON.parse(localStorage.getItem('userInfo')),
        });
      }
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await api.post('/auth/login', { username, password });
      const { data } = response.data;
      var userInfo = jwt_decode(data.token);
      setUserInfo({
        token: data.token,
        userInfo: userInfo,
      });
      localStorage.setItem('token', data.token);
      localStorage.setItem('userInfo', JSON.stringify(userInfo));

      const userResponse = await getUserById(userInfo.userid);
      localStorage.setItem('userInfo', JSON.stringify(userResponse));
      return response.data;
    } catch (error) {
      if (error?.response?.data?.error?.message) {
        throw new Error(error?.response?.data?.error?.message);
      } else {
        throw new Error(error?.message);
      }

    }
  };

  const CheckIsAgent = async () => {
    try {
      await APIIsAgent();
    } catch (error) {
      if (error?.message === '403' || error?.message === '401') {
        await logout();
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setUserInfo(null);
      }
    }
  };

  const CheckIsAgentOutside = async () => {
    try {
      await APIIsAgent();
    } catch (error) {
      if (error?.message === '403' || error?.message === '401') {
        await logout();
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setUserInfo(null);
      }
    }
  };


  const logout = async () => {
    try {
      await APILogout();
    } catch (error) {
      //throw new Error(error.response.data.error.message);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
      setUserInfo(null);
    }
  };

  const refreshTokens = async () => {
    try {
      const response = await api.post('/auth/refresh', {
        refreshToken: userInfo.userInfo.refreshToken,
      });
      const { data } = response.data;

      setUserInfo({
        token: data.token,
        userInfo: jwt_decode(data.token),
      });
      localStorage.setItem('token', data.token);
      localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
    } catch (error) {
      throw new Error(error.response.data.error.message);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userInfo,
        setUserInfo,
        login,
        logout,
        refreshTokens,
        CheckIsAgent,
        CheckIsAgentOutside,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider };
