import React, { useEffect, useState } from "react";
import { Modal, Button, ProgressBar } from "react-bootstrap";
import { uploadImage } from "../../utils/api";
import { isImageFile } from "../../utils/validate";

const MAX_IMAGES = 20;

const FileUploadModal = ({
  showModal,
  handleClose,
  setUploadedList,
  source,
}) => {
  const [show, setShow] = useState(showModal);

  const [errorMessage, setErrorMessage] = useState("");

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    const newFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (isImageFile(file)) {
        newFiles.push(file);
      }
    }

    setSelectedFiles((prevFiles) =>
      [...prevFiles, ...newFiles].slice(0, MAX_IMAGES)
    );

    event.target.value = null;
  };

  const handleDeleteFile = (fileIndex) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== fileIndex)
    );
  };

  const handleUpload = () => {
    setIsUploading(true);
    let totalFiles = selectedFiles.length;
    let uploadedFiles = 0;
    let uploadedList = [];

    const handleFileUpload = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          const formData = new FormData();
          formData.append("file", file);

          uploadImage(formData, source)
            .then((data) => {
              uploadedList.push(data);
            })
            .catch((error) => {
              console.error(`Upload failed: ${error.message}`);
            })
            .finally(() => {
              uploadedFiles++;
              setUploadProgress(Math.round((uploadedFiles / totalFiles) * 100));

              if (uploadedFiles === totalFiles) {
                setIsUploading(false);
                setUploadedList(uploadedList);
                setSelectedFiles([]);
                handleClose();
              }

              resolve();
            });
        };

        reader.readAsDataURL(file);
      });
    };

    selectedFiles.forEach((file) => {
      handleFileUpload(file);
    });
  };

  useEffect(() => {
    if (showModal) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [showModal]);

  return (
    <Modal
      show={show}
      dialogClassName="modal-xl modal-dialog-scrollable"
      enforceFocus={false}
      onHide={() => {}}
    >
      <Modal.Header>
        <Modal.Title>เพิ่มรูป</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage ? (
          <div className="row mb-3">
            <div className="alert alert-danger">{errorMessage}</div>
          </div>
        ) : null}
        <div className="mb-3">
          <label htmlFor="imageInput" className="form-label">
            เลือกรูปภาพ (สูงสุด {MAX_IMAGES} ภาพ)
          </label>
          <input
            type="file"
            id="imageInput"
            className="form-control"
            multiple
            onChange={handleFileSelect}
            disabled={isUploading}
          />
        </div>
        {selectedFiles.length > 0 && (
          <div className="d-flex flex-wrap mb-3">
            {selectedFiles.map((file, index) => (
              <div className="me-3 mb-3" key={index}>
                <div className="img-with-close-box">
                  <button
                    type="button"
                    className="btn btn-danger btn-sm mt-2 close-x-button"
                    onClick={() => handleDeleteFile(index)}
                    disabled={isUploading}
                  >
                    x
                  </button>
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width="200"
                    height="200"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
        {isUploading && (
          <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} 
          disabled={isUploading}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={!selectedFiles.length || isUploading}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploadModal;
