export const isImageFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const bytes = new Uint8Array(reader.result).subarray(0, 4);
      let isValidImage = false;

      // Check if file signature matches one of the known image file signatures
      const imageSignatures = [
        [0xff, 0xd8, 0xff], // JPEG
        [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], // PNG
        [0x47, 0x49, 0x46], // GIF
        [0x42, 0x4d], // BMP
      ];

      for (let i = 0; i < imageSignatures.length; i++) {
        const signature = imageSignatures[i];

        if (bytes.length < signature.length) {
          continue;
        }

        let matchesSignature = true;

        for (let j = 0; j < signature.length; j++) {
          if (bytes[j] !== signature[j]) {
            matchesSignature = false;
            break;
          }
        }

        if (matchesSignature) {
          isValidImage = true;
          break;
        }
      }

      resolve(isValidImage);
    };

    reader.readAsArrayBuffer(file);
  });
}

export const validateUserName = (username) => {
  // You can use any validation logic you want here
  const regex = /^[a-zA-Z0-9_-]{3,16}$/;
  return regex.test(username);
};

// Validate password
export const validatePassword = (password) => {
  // Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, and one number
  //const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/;
  const regex = /^.{6,}$/;
  return regex.test(password);
};

// Validate email
export const validateEmail = (email) => {
  // Email must be in the correct format, e.g. example@example.com
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

// Validate phone number
export const validatePhoneNumber = (phoneNumber) => {
  // Phone number must be in the correct format, e.g. +66812345678 or 0812345678
  const regex = /^(\+66|0)\d{9}$/;
  return regex.test(phoneNumber);
};