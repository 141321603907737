import React from 'react';
import {Link} from 'react-router-dom';

export default class BreadcrumbApp extends React.Component {
    render(){
        let heading = this.props.heading;
        let publicUrl = process.env.PUBLIC_URL+'/'
        let currentPage = this.props.currentPage ? this.props.currentPage : heading
        let Img2 = this.props.Img2 ? this.props.Img2 :'page-header-shape1.png'
        return (
            <>
                <section className="page-header">
                    <div className="page-header__bg"></div>

                    <div className="container">
                        <div className="page-header__inner">
                            <h2>{heading}</h2>
                            <ul className="thm-breadcrumb">
                                <li><Link to={process.env.PUBLIC_URL + `/`}>Home</Link></li>
                                <li><span className="pl-1 pr-1">&nbsp; - &nbsp;</span></li>
                                <li>{currentPage}</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}