import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderApp from "../common/header/HeaderApp";
import FooterApp from "../common/footer/FooterApp";
import BreadcrumbApp from "../common/breadcrumb/BreadcrumbApp";
import { AuthContext } from "../context/AuthContext";
import BlockUI from "../common/BlockUI/BlockUI";

export default function Login() {
  const { login } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  let publicUrl = process.env.PUBLIC_URL + "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await login(username, password);
      navigate("/agent");
    } catch (error) {
      setErrorMessage(
        error?.message ||
          "An error occurred while logging in. Please try again."
      );
      setIsLoading(false);
    }
  };

  return (
    <>
      <HeaderApp />
      <BreadcrumbApp heading="Login" currentPage="Login" />
      <section className="login-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="login-block__inner">
                <div className="shape1">
                  <img
                    src={
                      publicUrl + "assets/images/shapes/slogan-v1-shape1.png"
                    }
                    alt="#"
                  />
                </div>
                <div
                  className="login-block__bg"
                  style={{
                    backgroundImage:
                      "url(" +
                      publicUrl +
                      "assets/images/backgrounds/slogan-v1-bg.png)",
                  }}
                ></div>
                <div className="content-box2">
                    <form onSubmit={handleSubmit}>
                  {errorMessage ? (
                    <div className="row">
                      <div className="alert alert-danger">{errorMessage}</div>
                    </div>
                  ) : null}
                  <div className="row">
                      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 mt-2">
                        <div className="input-box">
                          <input
                            type="text"
                            placeholder="Username"
                            name="uname"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 mt-2">
                        <div className="input-box">
                          <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 mt-2">
                        <div className="button">
                          <button
                            className="btn btn-primary btn-block"
                            type="submit"
                            data-loading-text="Please wait..."
                            onClick={handleSubmit}
                            disabled={isLoading}
                          >
                            <span className="txt">Login</span>
                            <i className="fa fa-angle-double-right"></i>
                          </button>
                        </div>
                      </div>
                  </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterApp />
      <BlockUI blocking={isLoading} />
    </>
  );
}
