import React, { useContext, useEffect, useState, useRef} from "react";
import { useNavigate } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import {
  getAllUser,
  getUserById,
  sendEditUser,
  sendAddUser,
  getAllRoles,
} from "../../utils/api";
import { useTable, useSortBy } from "react-table";
import { BiWrench } from "react-icons/bi";
import { Button, Modal } from "react-bootstrap";
import BlockUI from "../../common/BlockUI/BlockUI";
import { validateUserName, validateEmail, validatePassword, validatePhoneNumber } from "../../utils/validate";

export default function AgentUser() {
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [selAddRole, setSelAddRole] = useState(0);
  const errorRef = useRef(null);

  const [formEditUser, setFormEditUser] = useState({
    id: "",
    userName: "",
    email: "",
    name: "",
    surName: "",
    phone: "",
  });
  const [formAddUser, setFormAddUser] = useState({
    id: null,
    userName: null,
    email: null,
    name: null,
    surName: null,
    phone: null,
    password: null,
  });

  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = async (id) => {
    var user = await getUserById(id);
    setErrorMessage("");
    setFormEditUser({
      id: id,
      userName: user.userName,
      email: user.email,
      name: user.name,
      surName: user.surName,
      phone: user.phone,
    });

    setShowEditModal(true);
  };
  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setFormEditUser({ ...formEditUser, [name]: value });
  };

  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = async () => {
    setErrorMessage("");
    setFormAddUser({
      id: null,
      userName: "",
      email: "",
      name: "",
      surName: "",
      phone: "",
      password: "",
    });

    setSelAddRole(roleList[0].id);

    setShowAddModal(true);
  };
  const handleAddInputChange = (event) => {
    const { name, value } = event.target;
    setFormAddUser({ ...formAddUser, [name]: value });
  };

  const handleAddRole = (event) => {
    setSelAddRole(event.target.value);
  };

  const handleSaveEditModal = async () => {
    
    if(!formEditUser.userName || !validateUserName(formEditUser.userName)){
      setErrorMessage("User Name is not valid");
      return;
    }
    if(!formEditUser.name){
      setErrorMessage("Name cannot be empty");
      return;
    }
    if(!formEditUser.email || !validateEmail(formEditUser.email)){
      setErrorMessage("Email is not valid");
      return;
    }
    if(!formEditUser.phone || !validatePhoneNumber(formEditUser.phone)){
      setErrorMessage("Phone is not valid");
      return;
    }
    let dataModel = {
      id: formEditUser.id,
      userName: formEditUser.userName,
      email: formEditUser.email,
      name: formEditUser.name,
      surName: formEditUser.surName,
      phone: formEditUser.phone,
    };
    setLoading(true);
    try {
      var result = await sendEditUser(dataModel);
      if (result.success) {
        // Application logic
        try {
          setLoading(true);
          const fetchData = async () => {
            let userList = await getAllUser();
            setUserList(userList);
            setLoading(false);
            handleCloseEditModal();
          };

          fetchData();
        } catch (error) {
          console.log(error);
          setLoading(false);
          handleCloseEditModal();
        } finally {
        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error?.message ||
          "An error occurred while logging in. Please try again."
      );
      setLoading(false);
    }
  };

  const handleSaveAddModal = async () => {
    if(!formAddUser.userName || !validateUserName(formAddUser.userName)){
      setErrorMessage("User Name is not valid");
      return;
    }
    if(!formAddUser.name){
      setErrorMessage("Name cannot be empty");
      return;
    }
    if(!formAddUser.email || !validateEmail(formAddUser.email)){
      setErrorMessage("Email is not valid");
      return;
    }
    if(!formAddUser.phone || !validatePhoneNumber(formAddUser.phone)){
      setErrorMessage("Phone is not valid");
      return;
    }
    if(!formAddUser.password || !validatePassword(formAddUser.password)){
      setErrorMessage("Password format is not valid");
      return;
    }
    let dataModel = {
      userName: formAddUser.userName,
      email: formAddUser.email,
      name: formAddUser.name,
      surName: formAddUser.surName,
      phone: formAddUser.phone,
      password: formAddUser.password,
      roles: [selAddRole],
    };
    setLoading(true);
    try {
      var result = await sendAddUser(dataModel);
      if (result.success) {
        // Application logic
        try {
          setLoading(true);
          const fetchData = async () => {
            let userList = await getAllUser();
            setUserList(userList);
            setLoading(false);
            handleCloseAddModal();
          };

          fetchData();
        } catch (error) {
          console.log(error);
          setLoading(false);
          handleCloseAddModal();
        } finally {
        }
      }
      setLoading(false);
    } catch (error) {
      setErrorMessage(
        error?.message ||
          "An error occurred while logging in. Please try again."
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage && errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (!storedToken) {
      logout();
      navigate("/login");
    } else {
      const uinfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!uinfo) {
        logout();
        navigate("/login");
      } else if (!userInfo) {
        setUserInfo({
          token: storedToken,
          userInfo: uinfo,
        });
      }
    }
  }, [logout, navigate, userInfo, setUserInfo]);

  useEffect(() => {
    if (
      userInfo &&
      !userInfo.userInfo.roles?.some((role) => role.code === "ADMIN")
    ) {
      navigate("/agent");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (userInfo) {
      const fetchData = async () => {
        setLoading(true);
        try {
          let userList = await getAllUser();
          let allRoleList = await getAllRoles();
          setUserList(userList);
          setRoleList(allRoleList);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [userInfo]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Username",
        accessor: "userName",
      },
      {
        Header: "Name",
        accessor: (row) => `${row.name} ${row.surName}`,
      },
      {
        Header: "E-Mail",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Roles",
        accessor: (row) => row.roles.map((role) => role.code).join(", "),
      },
      {
        Header: "Create On",
        accessor: "createTime",
      },
      {
        Header: "Edit",
        accessor: (row) => (
          <>
            <BiWrench
              onClick={() => {
                handleShowEditModal(row.id);
              }}
            />
          </>
        ),
      },
    ],
    []
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          initialState: {
            sortBy: [{ id: "createTime", desc: false }],
          },
        },
        useSortBy
      );

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20);

    return (
      <>
        <div className="table-responsive">
          <table {...getTableProps()} className="table table-bordered">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {firstPageRows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <br />
        <div>Showing the first 20 results of {rows.length} rows</div>
      </>
    );
  }
  return (
    <>
      <BlockUI blocking={loading} />
      <HeaderAgent />
      <Modal
        show={showEditModal}
        dialogClassName="modal-lg"
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>แก้ไขผู้ใช้</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage ? (
            <div className="row">
              <div className="alert alert-danger" ref={errorRef}>{errorMessage}</div>
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="tbxEditUserName" className="form-label">
              User Name
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxEditUserName"
              placeholder="UserName"
              name="userName"
              value={formEditUser.userName}
              onChange={handleEditInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxEditFirstName" className="form-label">
              ชื่อ
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxEditFirstName"
              placeholder="ชื่อ"
              name="name"
              value={formEditUser.name}
              onChange={handleEditInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxEditSurName" className="form-label">
              สกุล
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxEditSurName"
              placeholder="สกุล"
              name="surName"
              value={formEditUser.surName}
              onChange={handleEditInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxEditPhone" className="form-label">
              หมายเลขโทรศัพท์
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxEditPhone"
              placeholder="0812345678"
              name="phone"
              value={formEditUser.phone}
              onChange={handleEditInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxEditEmail" className="form-label">
              Email address
            </label>
            <input
              autoComplete="off"
              type="email"
              className="form-control"
              id="tbxEditEmail"
              placeholder="name@example.com"
              name="email"
              value={formEditUser.email}
              onChange={handleEditInputChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEditModal}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddModal}
        dialogClassName="modal-lg"
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>เพิ่มผู้ใช้</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage ? (
            <div className="row">
              <div className="alert alert-danger" ref={errorRef}>{errorMessage}</div>
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="tbxAddUserName" className="form-label">
              User Name
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxAddUserName"
              placeholder="UserName"
              name="userName"
              value={formAddUser.userName}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddFirstName" className="form-label">
              ชื่อ
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxAddFirstName"
              placeholder="ชื่อ"
              name="name"
              value={formAddUser.name}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddSurName" className="form-label">
              สกุล
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxAddSurName"
              placeholder="สกุล"
              name="surName"
              value={formAddUser.surName}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddPhone" className="form-label">
              หมายเลขโทรศัพท์
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxAddPhone"
              placeholder="0812345678"
              name="phone"
              value={formAddUser.phone}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddEmail" className="form-label">
              Email address
            </label>
            <input
              autoComplete="off"
              type="email"
              className="form-control"
              id="tbxAddEmail"
              placeholder="name@example.com"
              name="email"
              value={formAddUser.email}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddPassword" className="form-label">
              Password
            </label>
            <input
              autoComplete="off"
              type="password"
              className="form-control"
              id="tbxAddPassword"
              placeholder="Password"
              name="password"
              value={formAddUser.password}
              onChange={handleAddInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cbxAddRoleList" className="form-label">
              Role
            </label>
            <select
              autoComplete="off"
              id="cbxAddRoleList"
              className="form-select"
              aria-label="Role"
              value={selAddRole}
              onChange={handleAddRole}
            >
              {roleList.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.code}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveAddModal}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <section className="general-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>User List</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <button className="btn btn-primary" onClick={handleShowAddModal}>
                เพิ่มผู้ใช้
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {userList ? (
                <>
                  <Table columns={columns} data={userList} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterApp />
    </>
  );
}
