import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import {
  getAllUser,
  getUserById,
  sendEditUser,
  sendAddUser,
  getAllRoles,
  getAllFarms,
  getFarmById,
} from "../../utils/api";
import { useTable, useSortBy } from "react-table";
import { BiWrench } from "react-icons/bi";
import { Button, Modal } from "react-bootstrap";
import AddFarmModal from "../../components/agent/AddFarmModal";
import EditFarmModal from "../../components/agent/EditFarmModal";
import BlockUI from "../../common/BlockUI/BlockUI";

export default function AgentFarm() {
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFarmer, setIsFarmer] = useState(false);
  const [isAudit, setIsAudit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFarmData, setEditFarmData] = useState({});

  const [errorMessage, setErrorMessage] = useState("");
  const [farmList, setfarmList] = useState([]);

  const errorRef = useRef(null);

  const navigate = useNavigate();

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };
  const handleCloseAddModal = (isSuccess) => {
    if (isSuccess) {
      // Application logic
      try {
        setLoading(true);
        const fetchData = async () => {
          let farmList = await getAllFarms();
          setfarmList(farmList);
          setLoading(false);
        };

        fetchData();
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
      }
    }
    setShowAddModal(false);
  };
  const handleCloseEditModal = (isSuccess) => {
    setShowEditModal(false);
    if (isSuccess) {
      // Application logic
      try {
        setLoading(true);
        const fetchData = async () => {
          let farmList = await getAllFarms();
          setfarmList(farmList);
          setLoading(false);
        };

        fetchData();
      } catch (error) {
        console.log(error);
        setLoading(false);
      } finally {
      }
    }
  };

  const handleShowEditModal = async (farmId) => {
    setLoading(true);
    var farmData = await getFarmById(farmId);
    setLoading(false);
    setEditFarmData(farmData);
    setShowEditModal(true);
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      var uinfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!uinfo) {
        logout();
        navigate("/login");
      } else {
        if (!userInfo) {
          setUserInfo({
            token: storedToken,
            userInfo: uinfo,
          });
        }
        setIsAdmin(uinfo?.roles?.some((role) => role.code === "ADMIN"));
        setIsFarmer(uinfo?.roles?.some((role) => role.code === "FARMER"));
        setIsAudit(uinfo?.roles?.some((role) => role.code === "AUDITOR"));
        if (!uinfo?.roles?.some((role) => role.code === "ADMIN")) {
          navigate("/agent");
        }

        // Application logic
        try {
          setLoading(true);
          const fetchData = async () => {
            let farmList = await getAllFarms();
            // let allRoleList = await getAllRoles();
            setfarmList(farmList);
            // setRoleList(allRoleList);
            setLoading(false);
          };

          fetchData();
        } catch (error) {
          console.log(error);
          setLoading(false);
        } finally {
        }
      }
    } else {
      logout();
      navigate("/login");
    }
  }, [setUserInfo, logout, userInfo, navigate]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const columns = React.useMemo(
    () => [
      {
        Header: "Farm Name",
        accessor: "name",
      },
      {
        Header: "Address",
        accessor: "addr",
      },
      {
        Header: "OwnerName",
        accessor: "ownername",
      },
      {
        Header: "Create On",
        accessor: "createTime",
      },
      {
        Header: "Edit",
        accessor: (row) => (
          <>
            <Button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => {
                handleShowEditModal(row.id);
              }}
            >
              Edit
            </Button>
          </>
        ),
      },
    ],
    []
  );

  function Table({ columns, data }) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          initialState: {
            sortBy: [{ id: "createTime", desc: false }],
          },
        },
        useSortBy
      );

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case
    const firstPageRows = rows.slice(0, 20);
    
    return (
      <>
        <div className="table-responsive">
          <table {...getTableProps()} className="table table-bordered">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {firstPageRows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <br />
        <div>Showing the first 20 results of {rows.length} rows</div>
      </>
    );
  }

  return (
    <>
      <HeaderAgent />
      <AddFarmModal
        showModal={showAddModal}
        handleClose={handleCloseAddModal}
      />
      <EditFarmModal
        showModal={showEditModal}
        handleClose={handleCloseEditModal}
        initialData={editFarmData}
      />
      <section className="general-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>Farm List</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <button className="btn btn-primary" onClick={handleShowAddModal}>
                เพิ่มฟาร์ม
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              {farmList ? (
                <>
                  <Table columns={columns} data={farmList} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>
      <FooterApp />
      <BlockUI blocking={loading} />
    </>
  );
}
