import React from "react";
import { Link } from "react-router-dom";
import { Col, Card, Row } from "react-bootstrap";

export default class AgentReqAduitDetail extends React.Component {
  render() {
    const env = process.env.NODE_ENV || "development";
    const config = require(`../../config/appsettings${
      env === "production" ? "" : ".development"
    }`);
    const { formData, selectedFiles } = this.props;
    return (
      <form>
        {/* General */}
        <div className="row">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">ข้อมูลทั่วไป</h5>
              <div className="row">
                <div className="col-xl-6">
                  <div className="mb-3">
                    <label htmlFor="tbxReqYear" className="form-label">
                      ปีที่ยื่นคำขอ
                    </label>
                    <input
                      autoComplete="off"
                      type="number"
                      min={2566}
                      className="form-control"
                      id="tbxReqYear"
                      placeholder="2566"
                      name="year"
                      value={formData.year}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="mb-3">
                    <label htmlFor="tbxReqRound" className="form-label">
                      ครั้งที่ยื่นคำขอ (ไม่ต้องระบุ)
                    </label>
                    <input
                      autoComplete="off"
                      type="number"
                      min={0}
                      className="form-control"
                      id="tbxReqRound"
                      placeholder="1"
                      name="round"
                      value={formData.round}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="tbxFarmName" className="form-label">
                      ชื่อฟาร์ม
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      id="tbxFarmName"
                      placeholder="ชื่อฟาร์ม"
                      name="farmName"
                      value={formData.farmName}
                      readOnly={true}
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="tbxOwnerName" className="form-label">
                      เจ้าของฟาร์ม
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      id="tbxOwnerName"
                      placeholder="ชื่อเจ้าของฟาร์ม"
                      name="ownername"
                      value={formData.ownername}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <div className="mb-3">
                    <label htmlFor="tbxAddr" className="form-label">
                      ที่อยู่
                    </label>
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxAddr"
                      placeholder="ที่อยู่"
                      name="addr"
                      value={formData.addr}
                      readOnly={true}
                      rows="4"
                    />
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <label htmlFor="tbxLat" className="form-label">
                        Latitude
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        id="tbxLat"
                        placeholder="15.2494865"
                        name="lat"
                        value={formData.lat}
                        readOnly={true}
                        pattern="^\d*(\.\d{0,6})?$"
                        step={0.000001}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="tbxLng" className="form-label">
                        Longitude
                      </label>
                      <input
                        autoComplete="off"
                        type="number"
                        className="form-control"
                        id="tbxLng"
                        placeholder="104.8496195"
                        name="lng"
                        value={formData.lng}
                        readOnly={true}
                        pattern="^\d*(\.\d{0,6})?$"
                        step={0.000001}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-4">
                      <label htmlFor="tbxPhone" className="form-label">
                        โทรศัพท์
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="tbxPhone"
                        placeholder="0812345678"
                        name="phone"
                        value={formData.phone}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="tbxSite" className="form-label">
                        เว็บไซต์
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="tbxSite"
                        placeholder="https://www.google.com"
                        name="site"
                        value={formData.site}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="tbxLine" className="form-label">
                        Line ID
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="tbxLine"
                        placeholder="@ginsabaijai"
                        name="line"
                        value={formData.line}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ReqInfo Site 1 */}
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h5 className="card-title">ข้อมูลแปลงที่ 1</h5>
              </div>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="tbxSite1Name" className="form-label">
                    ชื่อแปลง
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id="tbxSite1Name"
                    placeholder="ชื่อแปลง"
                    name="site1name"
                    value={formData.site1name}
                    readOnly={true}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="tbxSite1Size" className="form-label">
                    ขนาดแปลง (ไร่)
                  </label>
                  <input
                    autoComplete="off"
                    type="number"
                    step={0.01}
                    className="form-control"
                    id="tbxSite1Size"
                    placeholder="0"
                    name="site1size"
                    value={formData.site1size}
                    readOnly={true}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <fieldset>
                    <legend
                      htmlFor="rbSite1Status"
                      className="form-label legend-label"
                    >
                      สถานะ
                    </legend>
                    <div
                      className="form-check form-check-inline"
                      id="rbSite1Status"
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="site1status"
                        id="rbSite1Ststus0"
                        value={0}
                        checked={formData.site1status === 0}
                        readOnly={true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rbSite1Ststus0"
                      >
                        ออแกนิค
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="site1status"
                        id="rbSite1Ststus1"
                        value={1}
                        checked={formData.site1status === 1}
                        readOnly={true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rbSite1Ststus1"
                      >
                        ปรับเปลี่ยน
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div className="mb-3">
                  <label htmlFor="tbxSite1Item" className="form-label">
                    รายการพื่ช/สัตว์ที่ขอรับรอง
                  </label>
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    id="tbxSite1Item"
                    placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                    name="site1item"
                    value={formData.site1item}
                    readOnly={true}
                    rows="4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ReqInfo Site 2 */}
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <h5 className="card-title">ข้อมูลแปลงที่ 2</h5>
              </div>
              <div className="row">
                <div className="mb-3">
                  <label htmlFor="tbxSite2Name" className="form-label">
                    ชื่อแปลง
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    id="tbxSite2Name"
                    placeholder="ชื่อแปลง"
                    name="site2name"
                    value={formData.site2name}
                    readOnly={true}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <label htmlFor="tbxSite2Size" className="form-label">
                    ขนาดแปลง (ไร่)
                  </label>
                  <input
                    autoComplete="off"
                    type="number"
                    step={0.01}
                    className="form-control"
                    id="tbxSite2Size"
                    placeholder="0"
                    name="site2size"
                    value={formData.site2size}
                    readOnly={true}
                  />
                </div>
                <div className="mb-3 col-md-6">
                  <fieldset>
                    <legend
                      htmlFor="rbSite2Status"
                      className="form-label legend-label"
                    >
                      สถานะ
                    </legend>
                    <div
                      className="form-check form-check-inline"
                      id="rbSite2Status"
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="site2status"
                        id="rbSite2Ststus0"
                        value={0}
                        checked={formData.site2status === 0}
                        readOnly={true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rbSite2Ststus0"
                      >
                        ออแกนิค
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="site2status"
                        id="rbSite2Ststus1"
                        value={1}
                        checked={formData.site2status === 1}
                        readOnly={true}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="rbSite2Ststus1"
                      >
                        ปรับเปลี่ยน
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div className="mb-3">
                  <label htmlFor="tbxSite2Item" className="form-label">
                    รายการพื่ช/สัตว์ที่ขอรับรอง
                  </label>
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    id="tbxSite2Item"
                    placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                    name="site2item"
                    value={formData.site2item}
                    rows="4"
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ReqImage */}
        <Row>
          <Card>
            <Card.Body>
              <Row>
                <h5 className="card-title">ภาพประกอบ</h5>
                {selectedFiles.map((img) => (
                  <>
                    <Col md={4} className="mb-4" key={img.id}>
                      <Card className="h-100">
                        <div className="card-image-container">
                          <Link
                            to={
                              config.apiUrl + "/registeraudit/image/" + img.id
                            }
                            target="_blank"
                          >
                            <Card.Img
                              variant="top"
                              src={
                                config.apiUrl + "/registeraudit/image/" + img.id
                              }
                              className="card-image"
                            />
                          </Link>
                        </div>
                        <Card.Body>
                          <Card.Text>
                            <div style={{ whiteSpace: "pre-wrap" }}>
                              {img.desc}
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </form>
    );
  }
}
