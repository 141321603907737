import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import ReactDOMServer from 'react-dom/server';
import Swal from 'sweetalert2';
import {
    getUserById,
    sendEditUser,
} from "../../utils/api";
import BlockUI from "../../common/BlockUI/BlockUI";
import { validateUserName, validateEmail, validatePhoneNumber } from "../../utils/validate";

export default function AgentAccount() {
    const { userInfo, setUserInfo, logout } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    const [errorMessage, setErrorMessage] = useState("");
    const [formEditUser, setFormEditUser] = useState({
        id: "",
        userName: "",
        email: "",
        name: "",
        surName: "",
        phone: "",
    });

    const navigate = useNavigate();

    const errorRef = useRef(null);
    useEffect(() => {
        if (errorMessage && errorRef.current) {
            errorRef.current.focus();
        }
    }, [errorMessage]);

    const handleEditInputChange = (event) => {
        const { name, value } = event.target;
        setFormEditUser({ ...formEditUser, [name]: value });
    };

    useEffect(() => {
        if (isFirstLoad) {
            setIsFirstLoad(false);

            setLoading(true);
            const storedToken = localStorage.getItem("token");
            if (storedToken) {
                let uinfo = JSON.parse(localStorage.getItem("userInfo"));
                if (!uinfo) {
                    logout();
                    navigate("/login");
                } else {
                    const fetchData = async () => {
                        uinfo = await getUserById(uinfo.id);
                        setUserInfo({
                            token: storedToken,
                            userInfo: uinfo,
                        });

                        // Application logic
                        try {
                            setFormEditUser(uinfo)
                        } catch (error) {
                            setErrorMessage(error);
                        } finally {
                            setLoading(false);
                        }
                    };
                    fetchData();
                }
            } else {
                logout();
                navigate("/login");
            }
        }
    }, [setUserInfo, logout, userInfo, navigate]);

    const handleSaveEdit = async () => {

        if (!formEditUser.userName || !validateUserName(formEditUser.userName)) {
            setErrorMessage("User Name is not valid");
            return;
        }
        if (!formEditUser.name) {
            setErrorMessage("Name cannot be empty");
            return;
        }
        if (!formEditUser.email || !validateEmail(formEditUser.email)) {
            setErrorMessage("Email is not valid");
            return;
        }
        if (!formEditUser.phone || !validatePhoneNumber(formEditUser.phone)) {
            setErrorMessage("Phone is not valid");
            return;
        }
        let dataModel = {
            id: formEditUser.id,
            email: formEditUser.email,
            name: formEditUser.name,
            surName: formEditUser.surName,
            phone: formEditUser.phone,
        };
        setLoading(true);
        try {
            setLoading(true);
            var result = await sendEditUser(dataModel).then((res) => {
                if (res.status === 200 || res.success) {
                    setErrorMessage("");
                    Swal.fire({
                        title: "Success",
                        text: "Save Success",
                        icon: "success",
                        confirmButtonText: "OK",
                    }).then((result) => {navigate('/agent/');});
                    setLoading(false);
                } else {
                    setErrorMessage(res.data.message);
                    setLoading(false);
                }
            })
                .catch((err) => {
                    setErrorMessage(err.message);
                    setLoading(false);
                });
        } catch (error) {
            setErrorMessage(
                error?.message ||
                "An error occurred while logging in. Please try again."
            );
            setLoading(false);
        }
    };

    return (
        <>
            <HeaderAgent />
            <BlockUI blocking={loading} />
            <section className="general-block">
                <div className="container">
                    {errorMessage ? (
                        <div className="row">
                            <div className="alert alert-danger" ref={errorRef}>{errorMessage}</div>
                        </div>
                    ) : null}
                    <div className="mb-3">
                        <label htmlFor="tbxEditUserName" className="form-label">
                            User Name
                        </label>
                        <input
                        readOnly={true}
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxEditUserName"
                            placeholder="UserName"
                            name="userName"
                            value={formEditUser.userName}
                            onChange={handleEditInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tbxEditFirstName" className="form-label">
                            ชื่อ
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxEditFirstName"
                            placeholder="ชื่อ"
                            name="name"
                            value={formEditUser.name}
                            onChange={handleEditInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tbxEditSurName" className="form-label">
                            สกุล
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxEditSurName"
                            placeholder="สกุล"
                            name="surName"
                            value={formEditUser.surName}
                            onChange={handleEditInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tbxEditPhone" className="form-label">
                            หมายเลขโทรศัพท์
                        </label>
                        <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxEditPhone"
                            placeholder="0812345678"
                            name="phone"
                            value={formEditUser.phone}
                            onChange={handleEditInputChange}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="tbxEditEmail" className="form-label">
                            Email address
                        </label>
                        <input
                            autoComplete="off"
                            type="email"
                            className="form-control"
                            id="tbxEditEmail"
                            placeholder="name@example.com"
                            name="email"
                            value={formEditUser.email}
                            onChange={handleEditInputChange}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <button type="button" className="btn btn-primary" onClick={handleSaveEdit}>บันทึก</button>
                            <button type="button" className="btn btn-secondary ms-5" onClick={() => { navigate(-1) }}>ยกเลิก</button>
                        </div>
                    </div>
                </div>
            </section>
            <FooterApp />
        </>
    );
}
