import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import FileUploadModal from "../general/FileUploadModal";
import { sendEditFarm } from "../../utils/api";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import BlockUI from "../../common/BlockUI/BlockUI";
import { farmState } from "../../utils/general";

const env = process.env.NODE_ENV || "development";
const config = require(`../../config/appsettings${
  env === "production" ? "" : ".development"
}`);
const ItemType = {
  IMAGE: "image",
};
const DraggableImage = ({ id, url, moveImage, handleDelete }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemType.IMAGE,
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: ItemType.IMAGE,
    hover(item) {
      if (item.id !== id) {
        moveImage(item.id, id);
      }
    },
  }));

  return (
    <Card
      ref={(node) => drag(drop(node))}
      className="mb-3 me-3"
      style={{ cursor: "move", opacity: isDragging ? 0.5 : 1 }}
    >
      <Card.Img src={url} />
      <Card.Footer>
        <Button variant="danger" onClick={() => handleDelete(id)}>
          ×
        </Button>
      </Card.Footer>
    </Card>
  );
};

const EditFarmModal = ({ showModal, handleClose, initialData }) => {
  const [show, setShow] = useState(showModal);
  const [loading, setLoading] = useState(false);
  const [showAddImage, setShowAddImage] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [debugMessage, setDebugMessage] = useState("");

  const errorRef = useRef(null);
  useEffect(() => {
    if (errorMessage && errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    desc: "",
    addr: "",
    lat: 15.2494865,
    lng: 104.8496195,
    phone: "",
    line: "",
    site: "",
    ownername: "",
    images: [],
    status: 0,
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCloseModal = () => {
    handleClose(false);
  };

  const handleDeleteFile = (id) => {
    setSelectedFiles(selectedFiles.filter((img) => img.id !== id));
  };

  const handleShowUploadImage = () => {
    setShowAddImage(true);
  };
  const handleCloseUploadImage = () => {
    setShowAddImage(false);
  };

  const handleSetUploadedList = (uploadedList) => {
    let newUploadedList = [];
    uploadedList.forEach((uploaded) => {
      newUploadedList.push({
        id: uploaded,
        desc: "",
        isDefault: false,
      });
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newUploadedList]);
  };

  const handleEditFarm = () => {
    if (!formData.name) {
      setErrorMessage("โปรดระบุชื่อฟาร์ม");
      return;
    }
    if (!formData.addr) {
      setErrorMessage("โปรดระบุที่อยู่");
      return;
    }
    setLoading(true);
    let newSlelectedFiles = [];
    {
      selectedFiles.map((img, index) => {
        newSlelectedFiles.push({
          id: img.id,
          desc: img.desc,
          isDefault: index === 0 ? true : false,
          order: index,
        });
      });
    }
    const data = {
      id: formData.id,
      name: formData.name,
      desc: formData.desc,
      addr: formData.addr,
      lat: formData.lat,
      lng: formData.lng,
      phone: formData.phone,
      line: formData.line,
      site: formData.site,
      ownername: formData.ownername,
      images: newSlelectedFiles,
      status: formData.status,
    };
    try {
      sendEditFarm(data)
        .then((res) => {
          if (res.status === 200 || res.success) {
            setErrorMessage("");
            handleClose(true);
            setLoading(false);
          } else {
            setErrorMessage(res.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
          setLoading(false);
        });
    } catch (err) {
      setErrorMessage(err);
      setLoading(false);
    }
  };

  const moveImage = (fromId, toId) => {
    const fromIndex = selectedFiles.findIndex((img) => img.id === fromId);
    const toIndex = selectedFiles.findIndex((img) => img.id === toId);
    const newImgList = [...selectedFiles];
    newImgList.splice(fromIndex, 1);
    newImgList.splice(toIndex, 0, selectedFiles[fromIndex]);
    setSelectedFiles(newImgList);
  };

  useEffect(() => {
    if (showModal) {
      setShow(true);
      setFormData(initialData);
      setSelectedFiles(initialData.images);
      setErrorMessage("");
    } else {
      setShow(false);
    }
  }, [showModal]);

  return (
    <>
      <BlockUI blocking={loading} />
      <Modal
        show={show}
        dialogClassName="modal-xl modal-dialog-scrollable"
        enforceFocus={false}
        style={show ? {
          zIndex: showAddImage ? -1 : 1050,
          opacity: showAddImage ? 0 : 1,
        } : { display: "none" }}
      >
        <Modal.Header>
          <Modal.Title>แก้ไขข้อมูลฟาร์ม</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage ? (
            <div className="row">
              <div className="alert alert-danger">{errorMessage}</div>
            </div>
          ) : null}
          <div className="mb-3">
            <label htmlFor="tbxFarmName" className="form-label">
              ชื่อฟาร์ม
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxFarmName"
              placeholder="ชื่อฟาร์ม"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxOwnerName" className="form-label">
              เจ้าของฟาร์ม
            </label>
            <input
              autoComplete="off"
              type="text"
              className="form-control"
              id="tbxOwnerName"
              placeholder="ชื่อเจ้าของฟาร์ม"
              name="ownername"
              value={formData.ownername}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxDesc" className="form-label">
              คำอธิบาย
            </label>
            <textarea
              autoComplete="off"
              className="form-control"
              id="tbxDesc"
              placeholder="คำอธิบาย"
              name="desc"
              value={formData.desc}
              onChange={handleInputChange}
              rows="4"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="tbxAddr" className="form-label">
              ที่อยู่
            </label>
            <textarea
              autoComplete="off"
              className="form-control"
              id="tbxAddr"
              placeholder="ที่อยู่"
              name="addr"
              value={formData.addr}
              onChange={handleInputChange}
              rows="4"
            />
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <label htmlFor="tbxLat" className="form-label">
                Latitude
              </label>
              <input
                autoComplete="off"
                type="number"
                className="form-control"
                id="tbxLat"
                placeholder="15.2494865"
                name="lat"
                value={formData.lat}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,6})?$"
                step={0.000001}
              />
            </div>
            <div className="col-lg-6">
              <label htmlFor="tbxLng" className="form-label">
                Longitude
              </label>
              <input
                autoComplete="off"
                type="number"
                className="form-control"
                id="tbxLng"
                placeholder="104.8496195"
                name="lng"
                value={formData.lng}
                onChange={handleInputChange}
                pattern="^\d*(\.\d{0,6})?$"
                step={0.000001}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-4">
              <label htmlFor="tbxPhone" className="form-label">
                โทรศัพท์
              </label>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                id="tbxPhone"
                placeholder="0812345678"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="tbxSite" className="form-label">
                เว็บไซต์
              </label>
              <input
                type="text"
                className="form-control"
                id="tbxSite"
                placeholder="https://www.google.com"
                name="site"
                value={formData.site}
                onChange={handleInputChange}
              />
            </div>
            <div className="col-lg-4">
              <label htmlFor="tbxLine" className="form-label">
                Line ID
              </label>
              <input
                autoComplete="off"
                type="text"
                className="form-control"
                id="tbxLine"
                placeholder="@ginsabaijai"
                name="line"
                value={formData.line}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3 align-items-end">
            <div className="col-lg-4">
              <label htmlFor="selStatus" className="form-label">
                สถานะ
              </label>
              <select
                autoComplete="off"
                id="selStatus"
                className="form-select"
                aria-label="Role"
                value={formData.status}
                onChange={handleInputChange}
              >
                {farmState.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-4 align-bottom">
              <button
                id="btnAddImage"
                className="btn btn-primary align-bottom"
                onClick={handleShowUploadImage}
              >
                เพิ่มรูปฟาร์ม
              </button>
            </div>
          </div>
          {selectedFiles.length > 0 && (
            <DndProvider backend={HTML5Backend}>
              <div className="container">
                <div className="row">
                  {selectedFiles.map((img) => (
                    <div key={img.id} className="col-3">
                      <DraggableImage
                        id={img.id}
                        url={config.apiUrl + "/farm/image/" + img.id}
                        moveImage={moveImage}
                        handleDelete={handleDeleteFile}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </DndProvider>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditFarm}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <FileUploadModal
        showModal={showAddImage}
        handleClose={handleCloseUploadImage}
        setUploadedList={handleSetUploadedList}
        source="farm"
      />
    </>
  );
};

export default EditFarmModal;
