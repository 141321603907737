import axios from "axios";

const env = process.env.NODE_ENV || "development";
const config = require(`../config/appsettings${
  env === "production" ? "" : ".development"
}`);

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the Authorization header with the bearer token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export const APIIsAgent = async (id) => {
  try {
  const response = await api.get(`/auth/isagent`);
  return response.data?.data;
  } catch (error) {
    if(error?.response?.status)
      throw new Error(error?.response?.status);
    else
      throw new Error(error?.message);
  }
};

export const logout = async () => {
  try {
    const response = await api.get(`/auth/logout`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getUserById = async (id) => {
  try {
    const response = await api.post(`/auth/getuserbyid`, {
      id: id,
    });
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getFarmById = async (id) => {
  try {
    const response = await api.post(`/farm/byid`, {
      id: id,
    });
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};
export const getFarmByUser = async () => {
  try {
    const response = await api.get(`/farm/byuser`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getAllUser = async () => {
  try {
    const response = await api.get(`/auth/getalluser`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const sendEditUser = async (userModel) => {
  try {
    const response = await api.post(`/auth/edituser`, userModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const sendAddUser = async (userModel) => {
  try {
    const response = await api.post(`/auth/register`, userModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getAllRoles = async () => {
  try {
    const response = await api.get(`/auth/getallroles`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getAllFarms = async () => {
  try {
    const response = await api.post(`/farm/list`, {});
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const sendAddFarm = async (farmModel) => {
  try {
    const response = await api.post(`/farm/register`, farmModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};
export const sendEditFarm = async (farmModel) => {
  try {
    const response = await api.post(`/farm/edit`, farmModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const sendAddReqAudit = async (reqAuditModel) => {
  try {
    const response = await api.post(`/registeraudit/add`, reqAuditModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};
export const sendEditReqAudit = async (reqAuditModel) => {
  try {
    const response = await api.post(`/registeraudit/edit`, reqAuditModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getReqAuditByFarmId = async (id) => {
  try {
    const response = await api.get(`registeraudit/farm?farmId=` + id);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getReqAuditList = async () => {
  try {
    const response = await api.get(`registeraudit/list`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getAuditList = async () => {
  try {
    const response = await api.get(`audit/list`);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getReqAuditById = async (id) => {
  try {
    const response = await api.get(`registeraudit/byid?reqAuditId=` + id);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const changeReqAuditStatus = async (reqAuditId, status, comment) => {
  try {
    const response = await api.post(`registeraudit/changestatus`, {
      rqid: reqAuditId,
      status: status,
      comment: comment,
    });
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
}

export const getAuditById = async (id) => {
  try {
    const response = await api.get(`audit/byid?auditId=` + id);
    return response.data?.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};
export const sendEditAudit = async (auditModel) => {
  try {
    const response = await api.post(`/audit/edit`, auditModel);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export const getProductById = async (id) => {
  try {
    const response = await api.get(`product/` + id);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};


export const uploadImage = async (formData, source) => {
  try {
    let path = "/farm/upload";
    if (source === "farm") {
      path = "/farm/upload";
    } else if (source === "reqaudit") {
      path = "/registeraudit/upload";
    } else if (source === "audit") {
      path = "/audit/upload";
    }
    const response = await api.post(path, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data?.data?.fileName;
  } catch (error) {
    if (error?.response?.data?.error?.message) {
      throw new Error(error?.response?.data?.error?.message);
    } else {
      throw new Error(error?.message);
    }
  }
};

export default api;
