import React, { useEffect, useState } from "react";
import HeaderApp from "../common/header/HeaderApp";
import FooterApp from "../common/footer/FooterApp";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getFarmById, getProductById } from "../utils/api";
import { getThaiDate } from "../utils/general";
import Gallery from "react-photo-gallery";
import ImageGallery from 'react-image-gallery';
import "../react-gallery.scss"

const env = process.env.NODE_ENV || "development";
const config = require(`../config/appsettings${
  env === "production" ? "" : ".development"
}`);

const Farm = () => {
  const { key } = useParams();
  const [farmData, setFarmData] = useState(null);
  const navigate = useNavigate();
  const [imageList, setImageList] = useState([]);

  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {
    if (!!key) {
      const fetchData = async () => {
        await getFarmById(key)
          .then((res) => {
            setFarmData(res);
            let newImgList = [];
            res.images.forEach(img => {
              newImgList.push({
                original: config.apiUrl + "/farm/image/" + img.id,
                thumbnail: config.apiUrl + "/farm/image/" + img.id,
              });
            });
            setImageList(newImgList);
          })
          .catch((err) => {
            navigate("/");
          });
      };
      fetchData();
    }
  }, []);

  return (
    <>
      <HeaderApp />
      <section className="general-block">
      {farmData && (
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>{farmData.name}</h1>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-8">
            <ImageGallery items={imageList} />
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div
                  className="sidebar__single sidebar__post wow animated fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h3 className="sidebar__title">{farmData.name}</h3>
                  <div className="sidebar__post-box">
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        {farmData.desc}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                       เกษตร : {farmData.ownername}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        ที่อยู่: {farmData.addr}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        โทรศัพท์: {farmData.phnoe}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        Lind ID: {farmData.line}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        WebSite: {farmData.site ?? (<Link to={farmData.site}>farmData.site</Link>)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </section>
      <FooterApp />
    </>
  );
};

export default Farm;
