import React from "react";
import HeroApp from "../components/hero/HeroApp";
import FeatureOne from "../components/feature/FeatureOne";
import AboutTwo from "../components/about/AboutTwo";
import CtaOne from "../components/cta/CtaOne";
import ServiceTwo from "../components/service/ServiceTwo";
import ProjectTwo from "../components/project/ProjectTwo";
import TeamOne from "../components/team/TeamOne";
import BrandTwo from "../components/brand/BrandTwo";
import WorkProcessTwo from "../components/work-process/WorkProcessTwo";
import PricingOne from "../components/pricing/PricingOne";
import TestimonialTwo from "../components/testimonial/TestimonialTwo";
import BlogTwo from "../components/blog/BlogTwo";
import HeaderApp from "../common/header/HeaderApp";
import FooterApp from "../common/footer/FooterApp";
import ServiceApp from "../components/service/ServiceApp";

const HomeApp = () => {
    return (
        <>
            <HeaderApp />
            <HeroApp />
            <FeatureOne />
            {/* <AboutTwo /> */}
            {/* <CtaOne /> */}
            <ServiceApp />
            {/* <ProjectTwo />
            <TeamOne />
            <BrandTwo />
            <WorkProcessTwo />
            <PricingOne />
            <TestimonialTwo />
            <BlogTwo /> */}
            <FooterApp />
        </>
    )
}

export default HomeApp;