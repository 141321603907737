import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import FooterApp from "../../common/footer/FooterApp";
import { AuthContext } from "../../context/AuthContext";
import HeaderAgent from "../../common/header/HeaderAgent";
import { Button, Col, Card, Row, Modal, Tabs, Tab } from "react-bootstrap";
import BlockUI from "../../common/BlockUI/BlockUI";
import FileUploadModal from "../../components/general/FileUploadModal";
import { getAuditById, sendEditAudit } from "../../utils/api";
import { getAuditStatusDisply } from "../../utils/audit";
import Swal from "sweetalert2";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/locale/th";
import "moment/locale/th";
import "react-datetime/css/react-datetime.css";

const env = process.env.NODE_ENV || "development";
const config = require(`../../config/appsettings${env === "production" ? "" : ".development"
  }`);

const DeleteFooter = ({ id, handleDelete }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const handleConfirmDelete = () => {
    handleDelete(id);
    setShowConfirm(false);
  };

  return (
    <Card.Footer>
      {showConfirm ? (
        <div className="delete-confirm">
          <p>ยืนยันการลบ</p>
          <div className="confirm-buttons">
            <Button variant="danger" onClick={handleConfirmDelete}>
              ใช่
            </Button>
            <Button
              variant="secondary"
              className="ms-2"
              onClick={() => setShowConfirm(false)}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant="danger"
          className="btn-sm"
          onClick={() => setShowConfirm(true)}
        >
          × ลบภาพ
        </Button>
      )}
    </Card.Footer>
  );
};

const CheckTable = ({
  heading,
  site1CheckName,
  site1TxtName,
  site2CheckName,
  site2TxtName,
  site1CheckValue,
  site1TxtValue,
  site2CheckValue,
  site2TxtValue,
  chk0word,
  chk1word,
  handleInputChange,
  handleBooleanChange,
  readOnly
}) => {
  return (
    <>
      <div className="row mb-3">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th colSpan="2" style={{ whiteSpace: "pre-line" }}>
                {heading}
              </th>
            </tr>
            <tr>
              <th className="text-center" style={{ width: "50%" }}>
                แปลงที่ 1
              </th>
              <th className="text-center" style={{ width: "50%" }}>
                แปลงที่ 2
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <div className="col-md-12">
                  <fieldset>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={site1CheckName}
                        id="site1CheckName0"
                        value={false}
                        checked={!site1CheckValue}
                        onChange={handleBooleanChange}
                        readOnly={readOnly}
                        disabled={readOnly}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="site1CheckName0"
                      >
                        {chk0word}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={site1CheckName}
                        id="site1CheckName1"
                        value={true}
                        checked={site1CheckValue}
                        onChange={handleBooleanChange}
                        readOnly={readOnly}
                        disabled={readOnly}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="site1CheckName1"
                      >
                        {chk1word}
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    id="site1TxtName"
                    placeholder=""
                    name={site1TxtName}
                    value={site1TxtValue}
                    onChange={handleInputChange}
                    rows="4"
                    readOnly={readOnly}
                  />
                </div>
              </td>
              <td style={{ width: "50%" }}>
                <div className="col-md-12">
                  <fieldset>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={site2CheckName}
                        id="site2CheckName0"
                        value={false}
                        checked={!site2CheckValue}
                        onChange={handleBooleanChange}
                        readOnly={readOnly}
                        disabled={readOnly}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="site2CheckName0"
                      >
                        {chk0word}
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={site2CheckName}
                        id="site2CheckName1"
                        value={true}
                        checked={site2CheckValue}
                        onChange={handleBooleanChange}
                        readOnly={readOnly}
                        disabled={readOnly}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="site2CheckName1"
                      >
                        {chk1word}
                      </label>
                    </div>
                  </fieldset>
                </div>
                <div className="col-md-12">
                  <textarea
                    autoComplete="off"
                    className="form-control"
                    id="site2TxtName"
                    placeholder=""
                    name={site2TxtName}
                    value={site2TxtValue}
                    onChange={handleInputChange}
                    rows="4"
                    readOnly={readOnly}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default function AgentAuditEdit() {
  const [isLocked, setIsLocked] = useState(false);
  const { userInfo, setUserInfo, logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [originFiles, setOriginFiles] = useState([]);
  const [showAddImage, setShowAddImage] = useState(false);
  const [tabKey, setTabKey] = useState("main");
  const [auditDate, setAuditDate] = useState(moment());
  const [checkBoxData, setCheckBoxData] = useState({
    storageHome: false,
    storagePlant: false,
    storageOther: false,
    storageNoCheck: false,
  });
  const location = useLocation();
  //const auditId = "0e66e742-3ea4-4865-a752-0617e34981b3";

  const errorRef = useRef(null);

  const formatDate = (date) => {
    const thaiDate = moment.tz(date, "Asia/Bangkok");
    const buddhistYear = thaiDate.format("GGGG");
    const formattedDate = thaiDate.format("DD MMMM ") + buddhistYear;
    return formattedDate;
  };

  useEffect(() => {
    if (errorMessage && errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const [formData, setFormData] = useState({
    id: "",
    round: 0,
    year: 0,
    farmName: "",
    desc: "",
    addr: "",
    lat: 0,
    lng: 0,
    phone: "",
    line: "",
    site: "",
    ownername: "",
    site1name: "",
    site1size: 0,
    site1item: "",
    site1status: 0,
    site2name: "",
    site2size: 0,
    site2item: "",
    site2status: 0,
    farm: {},
    requestImage: [],
    status: 0,
    auditDate: new Date(),
    memberId: "",
    auditor1: "",
    auditor2: "",
    auditor3: "",
    auditor4: "",
    auditor5: "",
    auditor6: "",
    auditor7: "",
    auditor8: "",
    auditor9: "",
    auditor10: "",
    sourceName: "",
    relation: "",
    storage: "",
    storageText: "",
    document: "",
    activityRecord: 0,
    activityRecordComment: "",
    organicAgreement: "",
    icsResult: 0,
    icsComment: "",
    s1d1r: null,
    s1d1c: "",
    s1d2r: null,
    s1d2c: "",
    s1d3r: null,
    s1d3c: "",
    s1d4r: null,
    s1d4c: "",
    s1d5r: null,
    s1d5c: "",
    s1d6r: null,
    s1d6c: "",
    s2d1r: null,
    s2d1c: "",
    s2d2r: null,
    s2d2c: "",
    s2d3r: null,
    s2d3c: "",
    s2d4r: null,
    s2d4c: "",
    s2d5r: null,
    s2d5c: "",
    s2d6r: null,
    s2d6c: "",
    s1m1r: null,
    s1m1c: "",
    s1m2r: null,
    s1m2c: "",
    s1m3r: null,
    s1m3c: "",
    s1m4r: null,
    s1m4c: "",
    s1m5r: null,
    s1m5c: "",
    s1m6r: null,
    s1m6c: "",
    s1m7r: null,
    s1m7c: "",
    s1m8r: null,
    s1m8c: "",
    s1m9r: null,
    s1m9c: "",
    s1m10r: null,
    s1m10c: "",
    s1m11r: null,
    s1m11c: "",
    s1m12r: null,
    s1m12c: "",
    s1m13r: null,
    s1m13c: "",
    s2m1r: null,
    s2m1c: "",
    s2m2r: null,
    s2m2c: "",
    s2m3r: null,
    s2m3c: "",
    s2m4r: null,
    s2m4c: "",
    s2m5r: null,
    s2m5c: "",
    s2m6r: null,
    s2m6c: "",
    s2m7r: null,
    s2m7c: "",
    s2m8r: null,
    s2m8c: "",
    s2m9r: null,
    s2m9c: "",
    s2m10r: null,
    s2m10c: "",
    s2m11r: null,
    s2m11c: "",
    s2m12r: null,
    s2m12c: "",
    s2m13r: null,
    s2m13c: "",
    s1s1r: null,
    s1s1c: "",
    s1s2r: null,
    s1s2c: "",
    s1s3r: null,
    s1s3c: "",
    s1s4r: null,
    s1s4c: "",
    s1s5r: null,
    s1s5c: "",
    s1s6r: null,
    s1s6c: "",
    s1s7r: null,
    s1s7c: "",
    s1s8r: null,
    s1s8c: "",
    s2s2r: null,
    s2s2c: "",
    s2s3r: null,
    s2s3c: "",
    s2s4r: null,
    s2s4c: "",
    s2s5r: null,
    s2s5c: "",
    s2s6r: null,
    s2s6c: "",
    s2s7r: null,
    s2s7c: "",
    s2s8r: null,
    s2s8c: "",
    talkFound: "",
    talkCondition: "",
    talkComment: "",
    auditComment: "",
    images: [],
    lastpage: "",
    createTime: new Date(),
  });

  const navigate = useNavigate();

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setCheckBoxData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "activityRecord" ||
      name === "icsResult" ||
      name === "status"
    ) {
      setFormData({ ...formData, [name]: parseInt(value) });
      return;
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: parseInt(value) });
    return;
  };

  const handleBooleanChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value == "true" });
    return;
  };

  const handleDeleteFile = (id) => {
    setSelectedFiles(selectedFiles.filter((img) => img.id !== id));
  };

  const handleShowUploadImage = () => {
    setShowAddImage(true);
  };
  const handleCloseUploadImage = () => {
    setShowAddImage(false);
  };

  const handleSetUploadedList = (uploadedList) => {
    let newUploadedList = [];
    uploadedList.forEach((uploaded) => {
      newUploadedList.push({
        id: uploaded,
        desc: "",
      });
    });
    setSelectedFiles((prevFiles) => [...prevFiles, ...newUploadedList]);
  };

  const handleImgDescChange = (e, id) => {
    const index = selectedFiles.findIndex((img) => img.id === id);
    const updatedFiles = [...selectedFiles];
    updatedFiles[index] = {
      ...updatedFiles[index],
      desc: e.target.value,
    };
    setSelectedFiles(updatedFiles);
  };

  const handleSave = async () => {
    if (isLocked) return;
    let checkedArray = [];
    if (checkBoxData.storageHome) checkedArray.push("1");
    if (checkBoxData.storagePlant) checkedArray.push("2");
    if (checkBoxData.storageOther) checkedArray.push("3");
    if (checkBoxData.storageNoCheck) checkedArray.push("4");

    let newSlelectedFiles = [];
    selectedFiles.map((img, index) => {
      newSlelectedFiles.push({
        id: img.id,
        desc: img.desc,
        order: index,
        isDefault: index === 0 ? true : false,
      });
    });
    const data = formData;
    formData.storage = checkedArray.join(",");
    formData.images = newSlelectedFiles;
    formData.logs = [];
    console.log(formData);

    if ((formData.status === 0 || formData.status === 1 || formData.status === 2)) {
      let approveConfirm = await Swal.fire({
        title: "ยืนยันการอุนมัติ",
        html: `คุณกำลังดำเนินการ <br /><br /><h4>"${getAuditStatusDisply(formData.status)}"</h4><br />หากดำเนินการอนุมัติแล้ว จะไม่สามารถแก้ไขแและไม่สามารถยกเลิกได้`,
        showCancelButton: true,
        icon: "warning",
      });

      if (!approveConfirm.isConfirmed) {
        return;
      }
    }

    const { value: text, dismiss } = await Swal.fire({
      title: 'บันทึกผล - ' + getAuditStatusDisply(formData.status),
      input: 'textarea',
      inputPlaceholder: 'ระบุเหตุผล',
      inputAttributes: {
        'aria-label': 'Type your message here'
      },
      showCancelButton: true,
      icon: 'warning',
    });
    if (dismiss === 'cancel') {
      return;
    }

    formData.comment = text;
    formData.lastpage = tabKey;

    setLoading(true);
    try {
      sendEditAudit(formData)
        .then((res) => {
          if (res.status === 200 || res.success) {
            setErrorMessage("");
            Swal.fire({
              icon: "success",
              title: "ดำเนินการสำเร็จ",
            })
            navigate("/agent/auditlist");
            setLoading(false);
          } else {
            Swal.fire({
              icon: "error",
              title: "เกิดข้อผิดพลาด",
              text: res.data.message,
            })
            setLoading(false);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "เกิดข้อผิดพลาด",
            text: err.message,
          })
          setLoading(false);
        });
    } catch (error) {

      Swal.fire({
        icon: "error",
        title: "เกิดข้อผิดพลาด",
        text: error.message,
      })
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        var uinfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!uinfo) {
          logout();
          navigate("/login");
        } else {
          if (!userInfo) {
            setUserInfo({
              token: storedToken,
              userInfo: uinfo,
            });
          }
          if (
            !(
              uinfo?.roles?.some((role) => role.code === "ADMIN") ||
              uinfo?.roles?.some((role) => role.code === "AUDITOR")
            )
          ) {
            navigate("/agent");
          }
          // Application logic
          try {
            setLoading(true);
            setSelectedFiles([]);
            const fetchData = async () => {
              let auditData = {};
              let auditId = location.state.id;
              await getAuditById(auditId)
                .then((res) => {
                  auditData = res;
                })
                .catch((err) => {
                  setErrorMessage(
                    "ไม่สามารถเรียกดูข้อมูลได้ โปรดติดต่อเจ้าหน้าที่"
                  );
                  setLoading(false);
                  return;
                });
              setIsLocked(auditData.status === 0 || auditData.status === 1 || auditData.status === 2)
              setFormData(auditData);
              setSelectedFiles(auditData.images);
              setOriginFiles(auditData.requestImage);
              let storageChecked = auditData.storage.split(",");
              setCheckBoxData({
                storageHome: storageChecked === "0",
                storagePlant: storageChecked === "1",
                storageOther: storageChecked === "2",
                storageNoCheck: storageChecked === "3",
              });
            };

            fetchData();
          } catch (error) {
            setErrorMessage(error);
          } finally {
            setLoading(false);
          }
        }
      } else {
        logout();
        navigate("/login");
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      <HeaderAgent />
      <section className="general-block">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>
                รายงานการตรวจฟาร์มเกษตรอินทรีย์ ครั้งที่ {formData.round}{" "}
                ประจำปีการผลิต {formData.year}
              </h1>
              <h4>ฟาร์ม: {formData.farmName}</h4>
            </div>
          </div>
          {errorMessage ? (
            <div className="row">
              <div className="col-xl-12">
                <div className="row">
                  <div className="alert alert-danger" ref={errorRef}>
                    {errorMessage}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <form>
            <Tabs
              id="controlled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab eventKey="main" title="ข้อมูลทั่วไป">
                {/* General */}
                <div className="row">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">ข้อมูลทั่วไป</h5>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3">
                            <label htmlFor="tbxFarmName" className="form-label">
                              ชื่อฟาร์ม
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="tbxFarmName"
                              placeholder="ชื่อฟาร์ม"
                              name="farmName"
                              value={formData.farmName}
                              // onChange={handleInputChange}
                              readOnly={true}
                            />
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="tbxOwnerName"
                              className="form-label"
                            >
                              เจ้าของฟาร์ม
                            </label>
                            <input
                              autoComplete="off"
                              type="text"
                              className="form-control"
                              id="tbxOwnerName"
                              placeholder="ชื่อเจ้าของฟาร์ม"
                              name="ownername"
                              value={formData.ownername}
                              onChange={handleInputChange}
                              readOnly={isLocked}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="mb-3">
                            <label htmlFor="tbxAddr" className="form-label">
                              ที่อยู่
                            </label>
                            <textarea
                              autoComplete="off"
                              className="form-control"
                              id="tbxAddr"
                              placeholder="ที่อยู่"
                              name="addr"
                              value={formData.addr}
                              onChange={handleInputChange}
                              rows="4"
                              readOnly={isLocked}
                            />
                          </div>
                          <div className="row mb-3">
                            <div className="col-lg-6">
                              <label htmlFor="tbxLat" className="form-label">
                                Latitude
                              </label>
                              <input
                                autoComplete="off"
                                type="number"
                                className="form-control"
                                id="tbxLat"
                                placeholder="15.2494865"
                                name="lat"
                                value={formData.lat}
                                onChange={handleInputChange}
                                pattern="^\d*(\.\d{0,6})?$"
                                step={0.000001}
                                readOnly={isLocked}
                              />
                            </div>
                            <div className="col-lg-6">
                              <label htmlFor="tbxLng" className="form-label">
                                Longitude
                              </label>
                              <input
                                autoComplete="off"
                                type="number"
                                className="form-control"
                                id="tbxLng"
                                placeholder="104.8496195"
                                name="lng"
                                value={formData.lng}
                                onChange={handleInputChange}
                                pattern="^\d*(\.\d{0,6})?$"
                                step={0.000001}
                                readOnly={isLocked}
                              />
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-lg-4">
                              <label htmlFor="tbxPhone" className="form-label">
                                โทรศัพท์
                              </label>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                id="tbxPhone"
                                placeholder="0812345678"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="tbxSite" className="form-label">
                                เว็บไซต์
                              </label>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                id="tbxSite"
                                placeholder="https://www.google.com"
                                name="site"
                                value={formData.site}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label htmlFor="tbxLine" className="form-label">
                                Line ID
                              </label>
                              <input
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                id="tbxLine"
                                placeholder="@ginsabaijai"
                                name="line"
                                value={formData.line}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ReqInfo Site 1 */}
                <div className="row">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <h5 className="card-title">ข้อมูลแปลงที่ 1</h5>
                      </div>
                      <div className="row">
                        <div className="mb-3">
                          <label htmlFor="tbxSite1Name" className="form-label">
                            ชื่อแปลง
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxSite1Name"
                            placeholder="ชื่อแปลง"
                            name="site1name"
                            value={formData.site1name}
                            onChange={handleInputChange}
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="tbxSite1Size" className="form-label">
                            ขนาดแปลง (ไร่)
                          </label>
                          <input
                            autoComplete="off"
                            type="number"
                            step={0.01}
                            className="form-control"
                            id="tbxSite1Size"
                            placeholder="0"
                            name="site1size"
                            value={formData.site1size}
                            onChange={handleInputChange}
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <fieldset>
                            <legend
                              htmlFor="rbSite1Status"
                              className="form-label legend-label"
                            >
                              สถานะ
                            </legend>
                            <div
                              className="form-check form-check-inline"
                              id="rbSite1Status"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="site1status"
                                id="rbSite1Ststus0"
                                value={0}
                                checked={formData.site1status === 0}
                                onChange={handleSelectChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rbSite1Ststus0"
                              >
                                ออแกนิค
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="site1status"
                                id="rbSite1Ststus1"
                                value={1}
                                checked={formData.site1status === 1}
                                onChange={handleSelectChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rbSite1Ststus1"
                              >
                                ปรับเปลี่ยน
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="tbxSite1Item" className="form-label">
                            รายการพื่ช/สัตว์ที่ขอรับรอง
                          </label>
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="tbxSite1Item"
                            placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                            name="site1item"
                            value={formData.site1item}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ReqInfo Site 2 */}
                <div className="row">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <h5 className="card-title">ข้อมูลแปลงที่ 2</h5>
                      </div>
                      <div className="row">
                        <div className="mb-3">
                          <label htmlFor="tbxSite2Name" className="form-label">
                            ชื่อแปลง
                          </label>
                          <input
                            autoComplete="off"
                            type="text"
                            className="form-control"
                            id="tbxSite2Name"
                            placeholder="ชื่อแปลง"
                            name="site2name"
                            value={formData.site2name}
                            onChange={handleInputChange}
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <label htmlFor="tbxSite2Size" className="form-label">
                            ขนาดแปลง (ไร่)
                          </label>
                          <input
                            autoComplete="off"
                            type="number"
                            step={0.01}
                            className="form-control"
                            id="tbxSite2Size"
                            placeholder="0"
                            name="site2size"
                            value={formData.site2size}
                            onChange={handleInputChange}
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="mb-3 col-md-6">
                          <fieldset>
                            <legend
                              htmlFor="rbSite2Status"
                              className="form-label legend-label"
                            >
                              สถานะ
                            </legend>
                            <div
                              className="form-check form-check-inline"
                              id="rbSite2Status"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="site2status"
                                id="rbSite2Ststus0"
                                value={0}
                                checked={formData.site2status === 0}
                                onChange={handleSelectChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rbSite2Ststus0"
                              >
                                ออแกนิค
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="site2status"
                                id="rbSite2Ststus1"
                                value={1}
                                checked={formData.site2status === 1}
                                onChange={handleSelectChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rbSite2Ststus1"
                              >
                                ปรับเปลี่ยน
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="tbxSite2Item" className="form-label">
                            รายการพื่ช/สัตว์ที่ขอรับรอง
                          </label>
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="tbxSite2Item"
                            placeholder="รายการพื่ช/สัตว์ที่ขอรับรอง คั่นด้วยสัญลักษณ์ , เช่น มะพร้าว, มะม่วง, มะละกอ"
                            name="site2item"
                            value={formData.site2item}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="basic" title="ข้อมูลเบื้องต้น">
                <div className="row">
                  <div className="col-md-4">
                    <label htmlFor="tbxMemberId" className="form-label">
                      รหัสสมาชิก
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      id="tbxMemberId"
                      placeholder="รหัสสมาชิก"
                      name="memberId"
                      value={formData.memberId}
                      onChange={handleInputChange}
                      readOnly={isLocked}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="tbxAuditDate" className="form-label">
                      วันที่ตรวจ
                    </label>
                    <Datetime
                      value={auditDate}
                      onChange={(date) => {
                        setAuditDate(date);
                      }}
                      timeFormat="HH:mm"
                      closeOnSelect
                      inputProps={{
                        placeholder: "วันเวลา",
                        readOnly: true,
                      }}
                      locale="th"
                      dateFormat="DD MMMM YYYY"
                      viewMode="days"
                      renderInput={(props) => (
                        <input
                          {...props}
                          name="tbxAuditDate"
                          className="form-control"
                          readOnly={true}
                        />
                      )}
                      readOnly={isLocked}
                    />
                  </div>

                  <div className="col-md-9">
                    <label htmlFor="tbxSourceName" className="form-label">
                      ผู้ให้ข้อมูล
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      id="tbxSourceName"
                      placeholder="ผู้ให้ข้อมูล"
                      name="sourceName"
                      value={formData.sourceName}
                      onChange={handleInputChange}
                      readOnly={isLocked}
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="tbxRelation" className="form-label">
                      ความสัมพันธ์
                    </label>
                    <input
                      autoComplete="off"
                      type="text"
                      className="form-control"
                      id="tbxRelation"
                      placeholder="ความสัมพันธ์"
                      name="relation"
                      value={formData.relation}
                      onChange={handleInputChange}
                      readOnly={isLocked}
                    />
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <legend
                        htmlFor="selStorageType"
                        className="form-label legend-label"
                      >
                        สถานที่จัดเก็บผลผลิตและอุปกรณ์
                      </legend>
                      <div
                        className="form-check form-check-inline"
                        id="selStorageType"
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="storageHome"
                          id="storageHome"
                          onChange={handleCheckBoxChange}
                          checked={checkBoxData.storageHome}
                          readOnly={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="storageHome"
                        >
                          บ้าน
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="storagePlant"
                          id="storagePlant"
                          onChange={handleCheckBoxChange}
                          checked={checkBoxData.storagePlant}
                          readOnly={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="storagePlant"
                        >
                          แปลงเกษตร
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="storageOther"
                          id="storageOther"
                          onChange={handleCheckBoxChange}
                          checked={checkBoxData.storageOther}
                          readOnly={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="storageOther"
                        >
                          อื่น ๆ
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="storageNoCheck"
                          id="storageNoCheck"
                          onChange={handleCheckBoxChange}
                          checked={checkBoxData.storageNoCheck}
                          readOnly={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="storageNoCheck"
                        >
                          ยังไม่ได้ตรวจ
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxStorage"
                      placeholder="สถานที่จัดเก็บผลผลิตและอุปกรณ์ (ระบุเพิ่มเติม)"
                      name="storageText"
                      value={formData.storageText}
                      onChange={handleInputChange}
                      readOnly={isLocked}
                      rows="4"
                    />
                  </div>
                  <div className="col-md-12">
                    <fieldset>
                      <legend
                        htmlFor="activityLog"
                        className="form-label legend-label"
                      >
                        บันทึกกิจกรรมการทำฟาร์ม
                      </legend>
                      <div
                        className="form-check form-check-inline"
                        id="activityLog"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="activityRecord"
                          id="activityRecordHave"
                          value={0}
                          checked={formData.activityRecord === 0}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="activityRecordHave"
                        >
                          มี
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="activityRecord"
                          id="activityRecordNoHave"
                          value={1}
                          checked={formData.activityRecord === 1}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="activityRecordNoHave"
                        >
                          ไม่มี
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="activityRecord"
                          id="activityRecordImprove"
                          value={2}
                          checked={formData.activityRecord === 2}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="activityRecordImprove"
                        >
                          ต้องปรับปรุง
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="activityRecord"
                          id="activityRecordCondition"
                          value={3}
                          checked={formData.activityRecord === 3}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="activityRecordCondition"
                        >
                          เงื่อนไข
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxActivityRecordComment"
                      placeholder="ความเห็นเพิ่มเติม บันทึกกิจกรรมการทำฟาร์ม"
                      name="activityRecordComment"
                      value={formData.activityRecordComment}
                      onChange={handleInputChange}
                      rows="4"
                      readOnly={isLocked}
                      disabled={isLocked}
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="tbxDocument" className="form-label">
                      เอกสารอื่น ๆ
                    </label>
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxDocument"
                      placeholder="เช่น มาตราฐาน หนังสือรับรอง หรือเอกสารอื่น ๆ"
                      name="document"
                      value={formData.document}
                      onChange={handleInputChange}
                      rows="4"
                      readOnly={isLocked}
                      disabled={isLocked}
                    />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="tbxOrganicAgreement" className="form-label">
                      ความเข้าใจในมาตรฐานเกษตรอินทรีย์ของผูผลิตเป็นอย่างไร
                    </label>
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxOrganicAgreement"
                      placeholder=""
                      name="organicAgreement"
                      value={formData.organicAgreement}
                      onChange={handleInputChange}
                      rows="4"
                      readOnly={isLocked}
                      disabled={isLocked}
                    />
                  </div>

                  <div className="col-md-12">
                    <fieldset>
                      <legend
                        htmlFor="selICSGPS"
                        className="form-label legend-label"
                      >
                        ผลการตรวจระบบควบคุมภายใน ICS-PGS กินสบายใจ
                      </legend>
                      <div
                        className="form-check form-check-inline"
                        id="activityLog"
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="icsResult"
                          id="icsResultHave"
                          value={0}
                          checked={formData.icsResult === 0}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="icsResultHave"
                        >
                          มี
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="icsResult"
                          id="icsResultNoHave"
                          value={1}
                          checked={formData.icsResult === 1}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="icsResultNoHave"
                        >
                          ไม่มี
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="icsResult"
                          id="icsResultImprove"
                          value={2}
                          checked={formData.icsResult === 2}
                          onChange={handleSelectChange}
                          readOnly={isLocked}
                          disabled={isLocked}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="icsResultImprove"
                        >
                          ไม่สมบูรณ์
                        </label>
                      </div>
                    </fieldset>
                  </div>
                  <div className="col-md-12">
                    <textarea
                      autoComplete="off"
                      className="form-control"
                      id="tbxICSComment"
                      placeholder="อธิบายเพิ่มเติม"
                      name="icsComment"
                      value={formData.icsComment}
                      onChange={handleInputChange}
                      rows="4"
                      readOnly={isLocked}
                    />
                  </div>
                </div>
              </Tab>
              <Tab eventKey="dont" title="ข้อห้าม 6 ข้อ">
                <CheckTable
                  heading={
                    "1.	ห้ามใช้สารเคมี ปุ๋ยเคมี ฮอร์โมนสังเคราะห์ ยากำจัดแมลง ยาฆ่าหญ้า สารเคมีสังเคราะห์ และมูลสัตว์กรงตับในทุกกระบวนการผลิต \r\n(การใช้สารชีวภัณฑ์ ต้องใช้สารชีวภัณฑ์ที่คณะกรรมการ PGS กินสบายใจอนุญาตเท่านั้น กรณีที่มีการผลิตปุ๋ยใช้เอง ต้องมีการบันทึกแหล่งที่มาของวัตถุดิบ และต้องผ่านกระบวนการหมักที่สมบูรณ์ ในระยะเวลาที่เหมาะสมก่อนนำมาใช้ในการทำเกษตรอินทรีย์)"
                  }
                  site1CheckName={"s1d1r"}
                  site1TxtName={"s1d1c"}
                  site2CheckName={"s2d1r"}
                  site2TxtName={"s2d1c"}
                  site1CheckValue={formData.s1d1r}
                  site1TxtValue={formData.s1d1c}
                  site2CheckValue={formData.s2d1r}
                  site2TxtValue={formData.s2d1c}
                  chk0word={"ไม่ใช้"}
                  chk1word={"ใช้สารเคมี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "2. ห้ามใช้อุจจาระ ปัสสาวะมนุษย์ ขยะจาก เทศบาล ขยะจากตลาดสด ของเสียจากโรงงาน ในการ ทําปุ๋ยหมักและปรับปรุง ดิน"
                  }
                  site1CheckName={"s1d2r"}
                  site1TxtName={"s1d2c"}
                  site2CheckName={"s2d2r"}
                  site2TxtName={"s2d2c"}
                  site1CheckValue={formData.s1d2r}
                  site1TxtValue={formData.s1d2c}
                  site2CheckValue={formData.s2d2r}
                  site2TxtValue={formData.s2d2c}
                  chk0word={"ไม่ใช้"}
                  chk1word={"ใช้"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "3. ห้ามใช้เมล็ดพันธุ์คลุกสารเคมี และห้ามใช้เมล็ดพันธุ์ที่ตัดต่อทางพันธุกรรม (GMO)\n( หากไม่สามารถหาเมล็ดพันธุ์อินทรีย์และจำเป็นต้องใช้เมล็ดพันธุ์ทั่วไป ให้นำเมล็ดพันธุ์ไปล้างน้ำก่อนนำไปเพาะปลูก น้ำจากการล้างเมล็ดพันธุ์ให้นำไปทิ้งข้างนอกแปลง )"
                  }
                  site1CheckName={"s1d3r"}
                  site1TxtName={"s1d3c"}
                  site2CheckName={"s2d3r"}
                  site2TxtName={"s2d3c"}
                  site1CheckValue={formData.s1d3r}
                  site1TxtValue={formData.s1d3c}
                  site2CheckValue={formData.s2d3r}
                  site2TxtValue={formData.s2d3c}
                  chk0word={"ไม่ใช้"}
                  chk1word={"ใช้"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "4. ห้ามปลูกพืชทั้งในระบบเกษตรอินทรีย์และเกษตรเคมีปะปนกัน"
                  }
                  site1CheckName={"s1d4r"}
                  site1TxtName={"s1d4c"}
                  site2CheckName={"s2d4r"}
                  site2TxtName={"s2d4c"}
                  site1CheckValue={formData.s1d4r}
                  site1TxtValue={formData.s1d4c}
                  site2CheckValue={formData.s2d4r}
                  site2TxtValue={formData.s2d4c}
                  chk0word={"ไม่ปะปนกัน"}
                  chk1word={"ปะปนกัน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={"5. ห้ามเผาตอซังหรือเศษวัสดุในแปลงเกษตร"}
                  site1CheckName={"s1d5r"}
                  site1TxtName={"s1d5c"}
                  site2CheckName={"s2d5r"}
                  site2TxtName={"s2d5c"}
                  site1CheckValue={formData.s1d5r}
                  site1TxtValue={formData.s1d5c}
                  site2CheckValue={formData.s2d5r}
                  site2TxtValue={formData.s2d5c}
                  chk0word={"ไม่เผา"}
                  chk1word={"เผา"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "6. ห้ามมีบรรจุภัณฑ์ ของสารเคมี ถุงปุ๋ยเคมีในบ้านหรือแปลงเกษตรอินทรีย์ และไม่ใช้ไม่เก็บปะปนกันกับการทําเกษตรเคมี\n( ถังฉีด กระสอบ ถุง ลังที่ใช้เก็บผลผลิตเกษตรอินทรีย์ต้องสะอาด )"
                  }
                  site1CheckName={"s1d6r"}
                  site1TxtName={"s1d6c"}
                  site2CheckName={"s2d6r"}
                  site2TxtName={"s2d6c"}
                  site1CheckValue={formData.s1d6r}
                  site1TxtValue={formData.s1d6c}
                  site2CheckValue={formData.s2d6r}
                  site2TxtValue={formData.s2d6c}
                  chk0word={"ไม่มี"}
                  chk1word={"มี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
              </Tab>
              <Tab eventKey="must" title="ข้อต้อง 13 ข้อ">
                <CheckTable
                  heading={
                    "1. เกษตรกรต้องทําเกษตรอินทรีย์เต็มแปลง ทั้งข้าว ผัก ผลไม้ และ อื่นๆ โดยมีพื้นที่เพาะปลูก อย่างน้อย 1 งาน"
                  }
                  site1CheckName={"s1m1r"}
                  site1TxtName={"s1m1c"}
                  site2CheckName={"s2m1r"}
                  site2TxtName={"s2m1c"}
                  site1CheckValue={formData.s1m1r}
                  site1TxtValue={formData.s1m1c}
                  site2CheckValue={formData.s2m1r}
                  site2TxtValue={formData.s2m1c}
                  chk0word={"เต็มแปลง"}
                  chk1word={"ไม่เต็มแปลง"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "2. พื้นที่การผลิตต้องไม่ มีการใช้สารเคมี ปุ๋ยเคมีหรือสิ่งต้องห้ามตามมาตรฐาน เป็นระยะเวลาไม่น้อยกว่า 12 เดือน สําหรับพืชผัก และ 18 เดือนสําหรับไม้ยืนต้น\n( ผลผลิตจึงจะได้รับการรับรองมาตรฐานเกษตรอินทรีย์ สมาชิกที่สมัครใหม่ต้องผ่านระยะปรับเปลี่ยนทุกคน )"
                  }
                  site1CheckName={"s1m2r"}
                  site1TxtName={"s1m2c"}
                  site2CheckName={"s2m2r"}
                  site2TxtName={"s2m2c"}
                  site1CheckValue={formData.s1m2r}
                  site1TxtValue={formData.s1m2c}
                  site2CheckValue={formData.s2m2r}
                  site2TxtValue={formData.s2m2c}
                  chk0word={"อินทรีย์"}
                  chk1word={"ปรับเปลี่ยน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "3. เกษตรกรต้องแจ้งพื้นที่การเกษตรทั้งที่ตัวเองครอบครอง เช่า หรือให้เช่า จะมีโฉนด หรือไม่ก็ตามให้ครบทุกแปลง และห้ามเปิดป่าสาธารณะเพื่อทําการเกษตร\n( กรณีมีการเพิ่มหรือลดขนาดพื้นที่การผลิตต้องแจ้งให้คณะกรรมการ PGS กินสบายใจ )"
                  }
                  site1CheckName={"s1m3r"}
                  site1TxtName={"s1m3c"}
                  site2CheckName={"s2m3r"}
                  site2TxtName={"s2m3c"}
                  site1CheckValue={formData.s1m3r}
                  site1TxtValue={formData.s1m3c}
                  site2CheckValue={formData.s2m3r}
                  site2TxtValue={formData.s2m3c}
                  chk0word={"แจ้งครบ"}
                  chk1word={"แจ้งไม่ครบ"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "4. แปลงเกษตรอินทรีย์ต้องแยกจากแปลงเกษตรเคมีอย่างชัดเจน อย่างน้อย 1 เมตร และถ้าแปลงข้างเคียงมีการใช้สารเคมีต้องจัดทําแนวกันชนป้องกันการปนเปื้อนทั้ง ทางอากาศและทางน้ำ\n( เช่น ปลูกพืชเป็นแนวกันชน พืชกันชนไม่ถือเป็นผลผลิตอินทรีย์ )"
                  }
                  site1CheckName={"s1m4r"}
                  site1TxtName={"s1m4c"}
                  site2CheckName={"s2m4r"}
                  site2TxtName={"s2m4c"}
                  site1CheckValue={formData.s1m4r}
                  site1TxtValue={formData.s1m4c}
                  site2CheckValue={formData.s2m4r}
                  site2TxtValue={formData.s2m4c}
                  chk0word={"มีกันชน"}
                  chk1word={"ไม่มีกันชน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "5. เกษตรกรต้องใช้น้ำภายในฟาร์มจากน้ำฝน บ่อบาดาล หรือสระน้ำในไร่นาเท่านั้น ห้ามใช้น้ำจากแหล่งอื่นๆ ภายนอกฟาร์ม1\n( ยกเว้นน้ำจากแปลงข้างเคียงที่ทำเกษตรอินทรีย์ ในกรณีมีความจำเป็นต้องใช้น้ำจากภายนอก เกษตรต้องมีบ่อพักน้ำและพืชซับสารเคมีก่อนนำน้ำไปใช้ประโยชน์ )"
                  }
                  site1CheckName={"s1m5r"}
                  site1TxtName={"s1m5c"}
                  site2CheckName={"s2m5r"}
                  site2TxtName={"s2m5c"}
                  site1CheckValue={formData.s1m5r}
                  site1TxtValue={formData.s1m5c}
                  site2CheckValue={formData.s2m5r}
                  site2TxtValue={formData.s2m5c}
                  chk0word={"ถูกมาตรฐาน"}
                  chk1word={"ผิดมาตรฐาน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "6. เกษตรกรต้องจัดเก็บผลผลิตในแต่ละสถานะ เช่น เกษตรอินทรีย์, ปรับเปลี่ยน แยก ออกจากกันอย่างชัดเจน และสามารถตรวจสอบย้อนกลับได้ กรณีเพิ่มชนิดพืชในแปลงต้องแจ้ง คณะกรรมการ PGS กิน สบายใจ"
                  }
                  site1CheckName={"s1m6r"}
                  site1TxtName={"s1m6c"}
                  site2CheckName={"s2m6r"}
                  site2TxtName={"s2m6c"}
                  site1CheckValue={formData.s1m6r}
                  site1TxtValue={formData.s1m6c}
                  site2CheckValue={formData.s2m6r}
                  site2TxtValue={formData.s2m6c}
                  chk0word={"แยกชัดเจน"}
                  chk1word={"ไม่แยก"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "7. เกษตรกรต้องจัดการขยะไม่ให้กระจัดกระจาย ห้ามเผาขยะ พลาสติก เพื่อป้องกันการปนเปื้อนในแปลงเกษตรอินทรีย์"
                  }
                  site1CheckName={"s1m7r"}
                  site1TxtName={"s1m7c"}
                  site2CheckName={"s2m7r"}
                  site2TxtName={"s2m7c"}
                  site1CheckValue={formData.s1m7r}
                  site1TxtValue={formData.s1m7c}
                  site2CheckValue={formData.s2m7r}
                  site2TxtValue={formData.s2m7c}
                  chk0word={"มีการจัดการ"}
                  chk1word={"ไม่มี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "8. เกษตรกรต้องให้ความสําคัญกับการส่งเสริมและขยายแนวคิดเกษตร อินทรีย์ โดยต้องผ่านการประชุม อบรม ความรู้เรื่องมาตรฐานและการทําเกษตรอินทรีย์ โดยการเข้าร่วมกันพัฒนากลุ่ม ร่วมประชุม หรือร่วมกิจกรรมที่กลุ่มจัดขึ้นทุกครั้ง หากขาดการติดต่อกัน 3 ครั้ง จะพิจารณาการเป็นสมาชิก"
                  }
                  site1CheckName={"s1m8r"}
                  site1TxtName={"s1m8c"}
                  site2CheckName={"s2m8r"}
                  site2TxtName={"s2m8c"}
                  site1CheckValue={formData.s1m8r}
                  site1TxtValue={formData.s1m8c}
                  site2CheckValue={formData.s2m8r}
                  site2TxtValue={formData.s2m8c}
                  chk0word={"ร่วม"}
                  chk1word={"ไม่ร่วม"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "9. เกษตรกรต้องมีการจดบันทึกกิจกรรมในแปลงทุกขั้นตอน ทั้งการเพาะปลูก การใช้ปัจจัย การผลิต การขายผลผลิต ให้สามารถตรวจสอบได้"
                  }
                  site1CheckName={"s1m9r"}
                  site1TxtName={"s1m9c"}
                  site2CheckName={"s2m9r"}
                  site2TxtName={"s2m9c"}
                  site1CheckValue={formData.s1m9r}
                  site1TxtValue={formData.s1m9c}
                  site2CheckValue={formData.s2m9r}
                  site2TxtValue={formData.s2m9c}
                  chk0word={"มีการจด"}
                  chk1word={"ไม่มี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "10. เกษตรกรที่ใช้รถเกี่ยวอุ้ม หรือเกี่ยวนวด ร่วมกันระหว่างเคมีและอินทรีย์จะต้องทําความ สะอาดรถเกี่ยวอุ้มก่อนนําไปใช้"
                  }
                  site1CheckName={"s1m10r"}
                  site1TxtName={"s1m10c"}
                  site2CheckName={"s2m10r"}
                  site2TxtName={"s2m10c"}
                  site1CheckValue={formData.s1m10r}
                  site1TxtValue={formData.s1m10c}
                  site2CheckValue={formData.s2m10r}
                  site2TxtValue={formData.s2m10c}
                  chk0word={"ถูกมาตรฐาน"}
                  chk1word={"ผิดมาตรฐาน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "11. เกษตรกรต้องใช้กระสอบที่ไม่บรรจุปุ๋ยเคมี และสารเคมีมาก่อนในการบรรจุและจัดเก็บข้าว"
                  }
                  site1CheckName={"s1m11r"}
                  site1TxtName={"s1m11c"}
                  site2CheckName={"s2m11r"}
                  site2TxtName={"s2m11c"}
                  site1CheckValue={formData.s1m11r}
                  site1TxtValue={formData.s1m11c}
                  site2CheckValue={formData.s2m11r}
                  site2TxtValue={formData.s2m11c}
                  chk0word={"ไม่ใช้"}
                  chk1word={"ใช้"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "12. ถ้ามีการเพาะปลูกพืชหลังนา ต้องทําในระบบเกษตรอินทรีย์เท่านั้นไม่ว่าพืชชนิดใดก็ตาม"
                  }
                  site1CheckName={"s1m12r"}
                  site1TxtName={"s1m12c"}
                  site2CheckName={"s2m12r"}
                  site2TxtName={"s2m12c"}
                  site1CheckValue={formData.s1m12r}
                  site1TxtValue={formData.s1m12c}
                  site2CheckValue={formData.s2m12r}
                  site2TxtValue={formData.s2m12c}
                  chk0word={"ถูกมาตรฐาน"}
                  chk1word={"ผิดมาตรฐาน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "13. ในการผลิตเห็ดอินทรีย์ เกษตรกรต้องแจ้งแหล่งที่มาของวัตถุดิบในการผลิตเห็ด กระบวนการ ผลิตเห็ดทุกขั้นตอนการจัดการฟาร์มเห็ด"
                  }
                  site1CheckName={"s1m13r"}
                  site1TxtName={"s1m13c"}
                  site2CheckName={"s2m13r"}
                  site2TxtName={"s2m13c"}
                  site1CheckValue={formData.s1m13r}
                  site1TxtValue={formData.s1m13c}
                  site2CheckValue={formData.s2m13r}
                  site2TxtValue={formData.s2m13c}
                  chk0word={"ถูกมาตรฐาน"}
                  chk1word={"ผิดมาตรฐาน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
              </Tab>
              <Tab eventKey="should" title="ข้อควร 8 ข้อ">
                <CheckTable
                  heading={
                    "1.	เกษตรกรควรเลือกแปลงที่เหมาะสมในการทําเกษตรอินทรีย์"
                  }
                  site1CheckName={"s1s1r"}
                  site1TxtName={"s1s1c"}
                  site2CheckName={"s2s1r"}
                  site2TxtName={"s2s1c"}
                  site1CheckValue={formData.s1s1r}
                  site1TxtValue={formData.s1s1c}
                  site2CheckValue={formData.s2s1r}
                  site2TxtValue={formData.s2s1c}
                  chk0word={"เหมาะสม"}
                  chk1word={"ไม่เหมาะสม"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "2.	เกษตรกรควรมีการออกแบบแปลงเกษตรอินทรีย์ให้มีความหลากหลาย"
                  }
                  site1CheckName={"s1s2r"}
                  site1TxtName={"s1s2c"}
                  site2CheckName={"s2s2r"}
                  site2TxtName={"s2s2c"}
                  site1CheckValue={formData.s1s2r}
                  site1TxtValue={formData.s1s2c}
                  site2CheckValue={formData.s2s2r}
                  site2TxtValue={formData.s2s2c}
                  chk0word={"ออกแบบ"}
                  chk1word={"ไม่ได้ออกแบบ"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "3.	เกษตรกรควรมีแหล่งน้ำเพื่อการเพาะปลูกอย่างเพียงพอ (บาดาล บ่อ น้ำตื้น สระ)"
                  }
                  site1CheckName={"s1s3r"}
                  site1TxtName={"s1s3c"}
                  site2CheckName={"s2s3r"}
                  site2TxtName={"s2s3c"}
                  site1CheckValue={formData.s1s3r}
                  site1TxtValue={formData.s1s3c}
                  site2CheckValue={formData.s2s3r}
                  site2TxtValue={formData.s2s3c}
                  chk0word={"มี"}
                  chk1word={"ไม่มี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "4.	เกษตรกรควรผลิตให้หลากหลายซึ่งประกอบไปด้วยพืชผักสวนครัว พืชผัก ล้มลุกอายุสั้น และผักยืนต้น รวมอย่างน้อย 50 ชนิด"
                  }
                  site1CheckName={"s1s4r"}
                  site1TxtName={"s1s4c"}
                  site2CheckName={"s2s4r"}
                  site2TxtName={"s2s4c"}
                  site1CheckValue={formData.s1s4r}
                  site1TxtValue={formData.s1s4c}
                  site2CheckValue={formData.s2s4r}
                  site2TxtValue={formData.s2s4c}
                  chk0word={"หลากหลาย"}
                  chk1word={"ไม่หลากหลาย"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "5.	เกษตรกรควรมีการปลูกไม้ยืนต้น ผลไม้ในแปลงเกษตรอินทรีย์เพิ่มขึ้นอย่างน้อย 20 ชนิด"
                  }
                  site1CheckName={"s1s5r"}
                  site1TxtName={"s1s5c"}
                  site2CheckName={"s2s5r"}
                  site2TxtName={"s2s5c"}
                  site1CheckValue={formData.s1s5r}
                  site1TxtValue={formData.s1s5c}
                  site2CheckValue={formData.s2s5r}
                  site2TxtValue={formData.s2s5c}
                  chk0word={"มี"}
                  chk1word={"ไม่มี"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "6.	เกษตรกรควรไถกลบตอซังหลังจากการเก็บเกี่ยวข้าวเสร็จ"
                  }
                  site1CheckName={"s1s6r"}
                  site1TxtName={"s1s6c"}
                  site2CheckName={"s2s6r"}
                  site2TxtName={"s2s6c"}
                  site1CheckValue={formData.s1s6r}
                  site1TxtValue={formData.s1s6c}
                  site2CheckValue={formData.s2s6r}
                  site2TxtValue={formData.s2s6c}
                  chk0word={"ไถกลบ"}
                  chk1word={"ไม่ไถกลบ"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "7.	เกษตรกรควรมีการเลี้ยงสัตว์อย่างน้อย 1-2 ตัว เช่น วัว ควาย หมู ไก่ เป็นต้น"
                  }
                  site1CheckName={"s1s7r"}
                  site1TxtName={"s1s7c"}
                  site2CheckName={"s2s7r"}
                  site2TxtName={"s2s7c"}
                  site1CheckValue={formData.s1s7r}
                  site1TxtValue={formData.s1s7c}
                  site2CheckValue={formData.s2s7r}
                  site2TxtValue={formData.s2s7c}
                  chk0word={"เลี้ยง"}
                  chk1word={"ไม่เลี้ยง"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
                <CheckTable
                  heading={
                    "8.	เกษตรกรควรตากข้าวอย่างน้อย 2-3 แดด ก่อนบรรจุและจําหน่ายหลังจากการเก็บเกี่ยวและนวด"
                  }
                  site1CheckName={"s1s8r"}
                  site1TxtName={"s1s8c"}
                  site2CheckName={"s2s8r"}
                  site2TxtName={"s2s8c"}
                  site1CheckValue={formData.s1s8r}
                  site1TxtValue={formData.s1s8c}
                  site2CheckValue={formData.s2s8r}
                  site2TxtValue={formData.s2s8c}
                  chk0word={"ถูกมาตรฐาน"}
                  chk1word={"ผิดมาตรฐาน"}
                  handleInputChange={handleInputChange}
                  handleBooleanChange={handleBooleanChange}
                  readOnly={isLocked}
                />
              </Tab>
              <Tab eventKey="summary" title="สรุป">
                {/* Talks */}
                <div className="row">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">สรุปผลการพูดคุยกับผู้ผลิต</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="tbxFound" className="form-label">
                            สิ่งที่ตรวจพบ
                          </label>
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="tbxFound"
                            placeholder=""
                            name="talkFound"
                            value={formData.talkFound}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="tbxCondition" className="form-label">
                            เงื่อนไขที่ผู้ผลิตต้องแก้ไขตามมาตรฐานและผู้ตรวจต้องติดตามภายในระยะเวลาที่กำหนด
                          </label>
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="tbxCondition"
                            placeholder=""
                            name="talkCondition"
                            value={formData.talkCondition}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="tbxComment" className="form-label">
                            ข้อเสนอแนะที่ผู้ผลิตควรปรับปรุง
                          </label>
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="tbxComment"
                            placeholder=""
                            name="talkComment"
                            value={formData.talkComment}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Summary */}
                <div className="row">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">สรุปผลการพูดคุยกับผู้ผลิต</h5>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="chkSet" className="form-label">
                            สรุปผลการพิจารณารับรอง
                          </label>
                          <fieldset>
                            <div
                              className="form-check form-check-inline"
                              id="chkSet"
                            >
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="chkPass"
                                value={0}
                                checked={formData.status == 0}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chkPass"
                              >
                                ผ่านการรับรองมาตรฐานแบบไม่มีเงื่อนไข
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="chkPassWithCondition"
                                value={1}
                                checked={formData.status == 1}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chkPassWithCondition"
                              >
                                ผ่านการรับรองมาตรฐานแบบมีเงื่อนไข
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="chkNotPass"
                                value={2}
                                checked={formData.status == 2}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chkNotPass"
                              >
                                ไม่ผ่าน
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="chkDelay"
                                value={3}
                                checked={formData.status == 3}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chkDelay"
                              >
                                เลื่อนการรับรอง
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="status"
                                id="chkDraft"
                                value={4}
                                checked={formData.status == 4}
                                onChange={handleInputChange}
                                readOnly={isLocked}
                                disabled={isLocked}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="chkDraft"
                              >
                                อยู่ระหว่างการประเมิน (ฉบับร่าง)
                              </label>
                            </div>
                          </fieldset>
                        </div>
                        <div className="col-md-12">
                          <textarea
                            autoComplete="off"
                            className="form-control"
                            id="auditComment"
                            placeholder=""
                            name="auditComment"
                            value={formData.auditComment}
                            onChange={handleInputChange}
                            rows="4"
                            readOnly={isLocked}
                            disabled={isLocked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="reqimage" title="ภาพจากใบคำขอ">
                {/* ReqImage */}
                <Row>
                  <Card>
                    <Card.Body>
                      <Row>
                        <h5 className="card-title">ภาพจากใบคำขอ</h5>
                        {originFiles ? (
                          originFiles.map((img) => (
                            <>
                              <Col md={4} className="mb-4" key={img.id}>
                                <Card className="h-100">
                                  <div className="card-image-container">
                                    <Link
                                      to={
                                        config.apiUrl +
                                        "/registeraudit/image/" +
                                        img.id
                                      }
                                      target="_blank"
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={
                                          config.apiUrl +
                                          "/registeraudit/image/" +
                                          img.id
                                        }
                                        className="card-image"
                                      />
                                    </Link>
                                  </div>
                                  <Card.Body>
                                    <Card.Text>
                                      <div style={{ whiteSpace: "pre-wrap" }}>
                                        {img.desc}
                                      </div>
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              </Tab>
              <Tab eventKey="auditimage" title="ภาพประกอบการตรวจ">
                {/* Audit */}
                <Row>
                  <Card>
                    <Card.Body>
                      <Row>
                        <h5 className="card-title">
                          ภาพประกอบการตรวจ
                          <Button
                            id="btnAddImage"
                            variant="primary"
                            className="align-bottom btn-sm ms-2"
                            onClick={handleShowUploadImage}
                          >
                            เพิ่มรูปประกอบ
                          </Button>
                        </h5>
                        {selectedFiles ? (
                          selectedFiles.map((img) => (
                            <>
                              <Col md={4} className="mb-4" key={img.id}>
                                <Card className="h-100">
                                  <div className="card-image-container">
                                    <Link
                                      to={
                                        config.apiUrl +
                                        "/audit/image/" +
                                        img.id
                                      }
                                      target="_blank"
                                    >
                                      <Card.Img
                                        variant="top"
                                        src={
                                          config.apiUrl +
                                          "/audit/image/" +
                                          img.id
                                        }
                                        className="card-image"
                                      />
                                    </Link>
                                  </div>
                                  <Card.Body>
                                    <Card.Text>
                                      {!isLocked ? (<><label
                                        htmlFor="tbxImgDesc"
                                        className="form-label"
                                      >
                                        คำอธิบายภาพ
                                      </label>
                                        <textarea
                                          autoComplete="off"
                                          className="form-control"
                                          id="tbxImgDesc"
                                          placeholder=""
                                          name="tbxImgDesc"
                                          value={img.desc}
                                          onChange={(e) =>
                                            handleImgDescChange(e, img.id)
                                          }
                                          rows="2"
                                        /></>) : (
                                        <div style={{ whiteSpace: "pre-wrap" }}>
                                          {img.desc}
                                        </div>)}

                                    </Card.Text>
                                  </Card.Body>
                                  {!isLocked ? (<DeleteFooter
                                    id={img.id}
                                    handleDelete={handleDeleteFile}
                                  />) : (<></>)}

                                </Card>
                              </Col>
                            </>
                          ))
                        ) : (
                          <></>
                        )}
                      </Row>
                    </Card.Body>
                  </Card>
                </Row>
              </Tab>
            </Tabs>

            {!isLocked && (<Row>
              <Col md={12}>
                <Button
                  variant="secondary"
                  className="ms-2"
                  onClick={() => {
                    navigate("/agent/reqaudit");
                  }}
                >
                  ยกเลิกการแก้ไข
                </Button>
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={() => {
                    handleSave(1);
                  }}
                >
                  บันทึก
                </Button>
              </Col>
            </Row>)}

          </form>
        </div>
      </section>
      <FooterApp />
      <FileUploadModal
        showModal={showAddImage}
        handleClose={handleCloseUploadImage}
        setUploadedList={handleSetUploadedList}
        source="audit"
      />
      <BlockUI blocking={loading} />
    </>
  );
}
