import React, { useEffect, useState } from "react";
import HeaderApp from "../common/header/HeaderApp";
import FooterApp from "../common/footer/FooterApp";
import { useNavigate, useParams, Link } from "react-router-dom";
import { getProductById } from "../utils/api";
import { getThaiDate } from "../utils/general";

const env = process.env.NODE_ENV || "development";
const config = require(`../config/appsettings${
  env === "production" ? "" : ".development"
}`);

const Product = () => {
  const { key } = useParams();
  const [productData, setProductData] = useState(null);
  const navigate = useNavigate();

  let publicUrl = process.env.PUBLIC_URL + "/";

  useEffect(() => {
    if (!!key) {
      const fetchData = async () => {
        await getProductById(key)
          .then((res) => {
            setProductData(res.data);
          })
          .catch((err) => {
            navigate("/");
          });
      };
      fetchData();
    }
  }, []);

  return (
    <>
      <HeaderApp />
      <section className="general-block">
      {productData && (
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h1>รายละเอียดผลิตภัณฑ์: {productData.name}</h1>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-8">
              <img
                src={config.apiUrl + "/product/image/" + productData.img}
                alt={productData.name}
                className="img-fluid rounded"
              />
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div
                  className="sidebar__single sidebar__post wow animated fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <h3 className="sidebar__title">{productData.name}</h3>
                  <div className="sidebar__post-box">
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        รายละเอียดเพิ่มเติม: {productData.desc}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                       ฟาร์มผู้ผลิต : <Link to={`/farm/${productData.farm.id}`} target="_blank">{productData.farm.name}</Link>
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        ผลิตเมื่อ: {getThaiDate(new Date(productData.mfg))}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        วันหมดอายุ: {getThaiDate(new Date(productData.exp))}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        จำนวนที่ผลิต: {productData.quantity}
                      </div>
                    </div>
                    <div className="sidebar__post-single">
                      <div className="sidebar__post-content-box">
                        ให้ข้อมูลเมื่อ: {getThaiDate(new Date(productData.createTime))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>)}
      </section>
      <FooterApp />
    </>
  );
};

export default Product;
